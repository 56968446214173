import React, { useState } from 'react';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import config from '../../../config/config';
import { LOGOUT } from '../../../redux copy/actionTypes';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:"20px"
};


export default function Account() {
    const loginData = useSelector((state) => state.auth.Auth);

    const [showPassword1, setShowPassword1] = useState(false);
    const[openModal,setOpenModal] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch()

    const [contactData, setContactData] = useState({
        phone_number: "",
        company_email: ""
    });

    const [passwordData, setPasswordData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    });
    const validationSchema = Yup.object({
        current_password: Yup.string().required('Current password is required'),
        new_password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one digit")
        .matches(
          /[@$!%*#?&]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    })
    const togglePasswordVisibility1 = () => {
        setShowPassword1((prevState) => !prevState);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2((prevState) => !prevState);
    };
    const togglePasswordVisibility3 = () => {
        setShowPassword3((prevState) => !prevState);
    };

    const handleClose = () =>{
        setOpenModal(false)
    }
    const handleOpen = () =>{
        setOpenModal(true)
    }
    // Handle Contact Info change
    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactData({
            ...contactData,
            [name]: value
        });
    };

    // Handle Password change
    const handlePasswordChange = async (e) => {
        const { name, value } = e.target;
        setPasswordData({
            ...passwordData,
            [name]: value
        });
        try {
            await validationSchema.validateAt(name, { [name]: value });
            setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
        }
    };

    // Handle contact info form submission
    const handleContactSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            phone_number: contactData.phone_number,
            company_email: contactData.company_email
        };

        try {
            const response = await axios.put(`${config.apiUrl}employer/contact-info`, payload, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                toast.success("Contact information updated successfully!");
                dispatch({ type: LOGOUT });
                setTimeout(() => {
                  window.location.reload(true);
                  window.location.href = `${config.baseUrl}login`
                }, 500);
            }
        } catch (error) {
            toast.error("Failed to update contact information. Please try again.");
            console.error("Error updating contact info:", error);
        }
    };

    // Handle password change form submission
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            current_password: passwordData.current_password,
            new_password: passwordData.new_password,
            confirm_password: passwordData.confirm_password
        };

        if (passwordData.new_password !== passwordData.confirm_password) {
            toast.error("New password and confirm password do not match.");
            return;
        }

        try {
            await validationSchema.validate(passwordData, { abortEarly: false });
            const response = await axios.put(`${config.apiUrl}employer/change-password`, payload, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                toast.success("Password changed successfully!");
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };

    return (
        <div>
            <Toaster />
            <h2 className='heading3'>Contact Info</h2>
            <form className="mt-5 form-theme" >
                <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                    <div>
                        <label className="block mb-2">Phone</label>
                        <input
                            type="text"
                            name="phone_number"
                            value={contactData.phone_number}
                            onChange={handleContactChange}
                            className="w-full border border-gray-300"
                            placeholder="Phone number"
                        />
                    </div>
                    <div>
                        <label className="block mb-2">Email</label>
                        <input
                            type="email"
                            name="company_email"
                            value={contactData.company_email}
                            onChange={handleContactChange}
                            className="w-full border border-gray-300"
                            placeholder="Email"
                        />
                    </div>
                </div>
                <button type="button" className="theme-btn bordered-btn py-2.5" onClick={handleOpen}>
                    <span className="color-text">Save changes</span>
                </button>
            </form>

            {/* <hr className='my-5'/> */}
            <h3 className="heading3 mt-5">Change Password </h3>
            <form className="mt-2 form-theme" onSubmit={handlePasswordSubmit}>
                <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                    <div>
                        <label className="block mb-2">Current Password<span className="text-red-500">*</span></label>
                        <div className="relative">
                            <input
                                type={showPassword1 ? "text" : "password"}
                                name="current_password"
                                value={passwordData.current_password}
                                onChange={handlePasswordChange}
                                className="w-full border border-gray-300"
                                placeholder="Current Password"
                            />
                            <span
                                onClick={togglePasswordVisibility1}
                                className="absolute right-3 top-2 cursor-pointer"
                            >
                                {showPassword1 ? <VisibilityOffIcon fontSize="20" /> : <VisibilityIcon fontSize="20" />}
                            </span>
                            {errors.current_password && <span className="text-red-500 text-xs">{errors.current_password}</span>}
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2">New Password<span className="text-red-500">*</span></label>
                        <div className="relative">
                            <input
                                type={showPassword2 ? "text" : "password"}
                                name="new_password"
                                value={passwordData.new_password}
                                onChange={handlePasswordChange}
                                className="w-full border border-gray-300"
                                placeholder="New Password"
                            />
                            <span
                                onClick={togglePasswordVisibility2}
                                className="absolute right-3 top-2 cursor-pointer"
                            >
                                {showPassword2 ? <VisibilityOffIcon fontSize="20" /> : <VisibilityIcon fontSize="20" />}
                            </span>
                            {errors.new_password && <span className="text-red-500 text-xs">{errors.new_password}</span>}
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2">Confirm Password<span className="text-red-500">*</span></label>
                        <div className="relative">
                            <input
                                type={showPassword3 ? "text" : "password"}
                                name="confirm_password"
                                value={passwordData.confirm_password}
                                onChange={handlePasswordChange}
                                className="w-full border border-gray-300"
                                placeholder="Confirm Password"
                            />
                            <span
                                onClick={togglePasswordVisibility3}
                                className="absolute right-3 top-2 cursor-pointer"
                            >
                                {showPassword3 ? <VisibilityOffIcon fontSize="20" /> : <VisibilityIcon fontSize="20" />}
                            </span>
                            {errors.confirm_password && <span className="text-red-500 text-xs">{errors.confirm_password}</span>}

                        </div>
                    </div>
                </div>
                <button type="submit" className="theme-btn bordered-btn py-2.5">
                    <span className="color-text">Save changes</span>
                </button>
            </form>
            <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                        Are you sure want to update Email or phone ? <br/>
                       <span className='text-xs'> On updating your email you will logout from the website</span>
                    </Typography>

                    <div className='justify-center items-center mt-5 flex'>

                        <button onClick={handleClose} color="primary" sx={{ mt: 2 }} className='py-2.5 px-5 me-2 mb-2  text-xs font-medium text-black rounded-lg bg-gray-300'>
                            No
                        </button>
                        <button onClick={handleContactSubmit} sx={{ mt: 2 }} className=' py-2.5 px-5 me-2 mb-2 text-white text-xs font-medium text-wh theme-btn   rounded-lg'>
                            Yes
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>

    );
}
