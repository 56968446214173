import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Select from 'react-select';
import useJobSearch from './useJobSearch'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getSkills } from "../../../utils/candidate.api/candidate.api.services";
import InputAdornment from '@mui/material/InputAdornment';



const noticePeriods = [
    { label: 'Immediate/15 days', value: 'Immediate/15 days' },
    { label: '30 days', value: '30 days' },
    { label: '60 days', value: '60 days' },
    { label: '90 days or more', value: '90 days or more' },
    { label: 'Serving notice period', value: 'Serving notice period' }
];
const options = [
    { label: 'Full Time', value: 'Full-time' },
    { label: 'Part Time', value: 'Part-time' },
    { label: 'Internship', value: 'Internship' },
    { label: 'Contractual', value: 'Contractual' }
];
const jobModes = [
    { label: 'Remote', value: 'Remote' },
    { label: 'On-site', value: 'On-site' },
    { label: 'Hybrid', value: 'Hybrid' }
];
const Filters = ({ filters, setFilters }) => {
    const loginData = useSelector((state) => state.auth.Auth);
    const { handleSearch } = useJobSearch(loginData);
    const [skills, setSkills] = useState([]);

    // Handle skill selection change
    const handleSkillChange = async(selectedOptions) => {
        const updatedSkills = selectedOptions.map((option) => ({
            name: option.value,
            level: option.level || 1, 
        }));
       //setSelectedSkills(updatedSkills);
        setFilters(prev => ({
            ...prev,
            skill_set: updatedSkills
        }));
       await handleSearch(filters)
    };


    const handleChange = async(event) => {
        const { name, value } = event.target;  
      //  console.log(name, value);  

        
        if (name === "work_experience_min" || name === "work_experience_max") {
            const numericValue = value ? parseInt(value, 10) : '';  
            setFilters(prev => ({
                ...prev,
                [name]: numericValue,  
            }));
        }
        else if (name === "expected_salary") {
            setFilters(prev => ({
                ...prev,
                expected_salary_min: value[0] ,
                expected_salary_max: value[1] ,
            }));
        }
        else {
            setFilters(prev => ({
                ...prev,
                [name]: value,  
            }));
        }
       await handleSearch(filters)
    };
    const [isOpen, setIsOpen] = useState({
        jobType: true,
        jobMode: true,
        noticePeriod:true,
    });
    


    const toggleDropdown = (name) => {
        console.log(name);
        setIsOpen({ ...isOpen, [name]: !isOpen[name] });
    };

    const handleCheckboxChange = async(option, name) => {
        //console.log(option)
        setFilters(prev => ({
            ...prev,
            [name]: prev[name].includes(option)
                ? prev[name].filter(item => item !== option)
                : [...prev[name], option]
        }));
        // console.log(filters)
       await handleSearch(filters)
    };
    const handleRadioChange = async(option,name) => {
        
        setFilters(prev => ({
            ...prev,
            [name]: option
        }));
        await handleSearch(filters)
    };
   

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                let response = await getSkills();
                const fetchedSkills = response.data.map(skill => skill.toLowerCase()); // Convert fetched skills to lowercase
                const skillOptions = fetchedSkills.map(skill => ({ label: skill, value: skill }));
                setSkills(skillOptions);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSkills();
    }, []);

    return (
    
        <div className=" mx-auto p-6 bg-white rounded-lg shadow-md w-full">
            <h2 className="text-xl text-center mb-3 border-b p-2 menu-heading"> All Filters</h2>
           
                 <div className="flex flex-col mb-2 border-b">
                <div className="w-full border-none h-7 rounded flex justify-between items-center cursor-pointer" onClick={() => toggleDropdown("jobType")}>
                        <span className="ml-1 font-semibold text-sm">
                             Job Type
                        </span>
                        <span>
                            {isOpen.jobType ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                        </span>
                    </div>
                    {isOpen.jobType && (
                        <div className="relative m-1 bg-white border-none z-10 w-full rounded">
                            {options.map((option) => (
                                <div key={option} className="flex items-center p-1 gap-2">
                                    <input
                                        type="checkbox"
                                        value={option.value}
                                        checked={filters.job_types.includes(option)}
                                        onChange={() => handleCheckboxChange(option,'job_types')}
                                        className="mr-2"
                                        style={{
                                            width: '15px',   
                                            height: '15px',
                                        }}
                                    />
                                    <label className="text-sm text-gray-600 font-medium">{option.label}</label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            {/* <div className="block mb-2">
                <label className='block mb-1 font-bold text-sm'>Job Type</label>
                <select onChange={handleChange} name="job_type" className="block w-full p-2 border border-gray-300 rounded-md">
                    <option value="" hidden>Select Job Type</option>
                    <option value="Full-time">Full Time</option>
                    <option value="Part-time">Part Time</option>
                    <option value="Internship">Internship</option>
                    <option value="Contractual">Contractual</option>
                </select>
            </div> */}
            <div className="flex flex-col mb-3 border-b">
                <div className="w-full border-none h-7 rounded flex justify-between items-center cursor-pointer" onClick={() => toggleDropdown("jobMode")}>
                    <span className="ml-1 font-semibold text-sm">
                        Work Mode
                    </span>
                    <span>
                        {isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                    </span>
                </div>
                {isOpen.jobMode && (
                    <div className="relative m-1 bg-white border-none z-10 w-full rounded">
                        {jobModes.map((option) => (
                            <div key={option} className="flex items-center p-1 gap-2">
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={filters.job_modes.includes(option)}
                                    onChange={() => handleCheckboxChange(option,"job_modes")}
                                    className="mr-2 border border-4 border-gray-300"
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                                <label className="text-sm text-gray-600 font-medium">{option.label}</label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex flex-col mb-3 border-b">
                <div
                    className="w-full border-none h-7 rounded flex justify-between items-center cursor-pointer"
                    onClick={() => toggleDropdown("noticePeriod")}
                >
                    <span className="ml-1 font-semibold text-sm">
                        Notice Period
                    </span>
                    <span>
                        {isOpen.noticePeriod ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                    </span>
                </div>
                {isOpen.noticePeriod && (
                    <div className="relative m-1 bg-white border-none z-10 w-full rounded">
                        {noticePeriods.map((option) => (
                            <div key={option.value} className="flex items-center p-1 gap-2">
                                <input
                                    type="radio"
                                    name="noticePeriod"  // Ensure all options are grouped under the same name for single selection
                                    value={option.value}
                                    checked={filters.notice_period === option}
                                    onChange={() => handleRadioChange(option, "notice_period")}  // Update function for radio button change
                                    className="mr-2"
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                                <label className="text-sm text-gray-600 font-medium">{option.label}</label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className='flex flex-col mb-3'>
                <label className='block mb-2 font-semibold text-sm'>Work Experience <span className='font-light text-xs text-gray-400'>| Yrs</span></label>
                <div className="block mb-1 flex gap-4">
                    <TextField
                        id="outlined-input-min"
                        type="number"
                        onChange={handleChange}
                        name="work_experience_min"
                        label="Min"
                        size="small"
                        value={filters.work_experience_min}
                        inputProps={{ step: 1, min: 0, style: { fontSize: '14px' } }}  
                    />
                    <TextField
                        id="outlined-input-max"
                        type="number"
                        label="Max"
                        onChange={handleChange}
                        name="work_experience_max"
                        size="small"
                        value={filters.work_experience_max}
                        inputProps={{ step: 1, min: 0, style: { fontSize: '14px' } }} 
                    />

                </div>
            </div>
            

            <div className="flex flex-col mb-3">
                <label className="block mb-2 font-semibold text-sm">
                    Expected Salary <span className="font-light text-xs text-gray-400">| Yearly</span>
                </label>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    alignItems: 'center',
                    margin: '0 auto'
                }}>
                    <TextField
                        id="outlined-basic-min"
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { fontSize: '14px' } }}
                        value={filters.expected_salary_min}
                        onChange={(e) => {
                            const value = parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0;
                            setFilters((prev) => ({
                                ...prev,
                                expected_salary_min: value, // Update directly
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                    />
                    <div className="font-light text-xs text-gray-400">to</div>
                    <TextField
                        id="outlined-basic-max"
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { fontSize: '14px' } }}
                        value={filters.expected_salary_max}
                        onChange={(e) => {
                            const value = parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0;
                            setFilters((prev) => ({
                                ...prev,
                                expected_salary_max: value, // Update directly
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                    />
                </Box>

                <Box sx={{
                    width: '75%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    paddingTop: '30px'
                }}>
                    <Slider
                        getAriaLabel={() => 'Expected Salary'}
                        value={[
                            Math.min(filters.expected_salary_min, filters.expected_salary_max), // Ensure slider syncs with min/max
                            Math.max(filters.expected_salary_min, filters.expected_salary_max),
                        ]}
                        onChange={(e, newValue) => {
                            setFilters((prev) => ({
                                ...prev,
                                expected_salary_min: newValue[0], // Sync slider with fields
                                expected_salary_max: newValue[1],
                            }));
                        }}
                        size="large"
                        valueLabelDisplay="auto"
                        min={0}
                        max={5000000}
                    />
                </Box>
            </div>



            <div className="block mb-2">
                <label className='block mb-2 font-semibold text-sm'>Skill set</label>
                <div className='flex flex-col gap-2'>
                    <Select
                        isMulti
                        name="skills"
                        options={skills}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSkillChange}
                        value={filters.skill_set.map((skill) => ({
                            label: skill.name,
                            value: skill.name,
                        }))}
                        placeholder="Select skills"
                    />

                    
                </div>
            </div>
        </div>
    );
};

export default Filters;