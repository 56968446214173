import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function JobDetails({ jobDetails, fetchJobsRecommendations, fetchJobDetails }) {
    const [companyLogoUrl, setCompanyLogoUrl] = useState(""); // Store company logo URL
    const loginData = useSelector((state) => state.auth.Auth);
    console.log(jobDetails);

    const saveJob = async (jobId) => {
        try {
            console.log("access token", loginData.access_token);
            const response = await axios.post(
                `${config.apiUrl}candidate/save-job/${jobId}`,
                {}, // Empty body if no data is required
                {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 201) {
                // console.log("Job saved successfully!", response.data);
                toast.success(response.data.message);
                await fetchJobDetails(jobId);
                await fetchJobsRecommendations();
    
            }
        } catch (error) {
            console.error("Error saving job:", error);
            // Handle error - you might want to display an error message to the user
        }
    };

    const unSaveJob = async (jobId) => {
        try {
            console.log("access token", loginData.access_token);
            const response = await axios.post(
                `${config.apiUrl}candidate/unsave-job/${jobId}`,
                {}, // Empty body if no data is required
                {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 200) {
                // console.log("Job saved successfully!", response.data);
                toast.success(response.data.message);
                await fetchJobDetails(jobId);
                await fetchJobsRecommendations();
               
            }
        } catch (error) {
            console.error("Error saving job:", error);
            // Handle error - you might want to display an error message to the user
        }
    };

    // Function to fetch the company logo using the logoId
    const fetchCompanyLogo = async (logoId) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/company-logo/${logoId}`, {
                responseType: 'blob',
            });
            const logoUrl = URL.createObjectURL(response.data);
            setCompanyLogoUrl(logoUrl); // Set the company logo URL in state
        } catch (error) {
            console.log('Error fetching company logo:', error);
            return null;
        }
    };

    useEffect(() => {
        if (jobDetails?.company_logo_id) {
            fetchCompanyLogo(jobDetails.company_logo_id); // Fetch company logo if ID is present
        }
    }, [jobDetails?.company_logo_id]);

    useEffect(() => {
        
    }, [saveJob, unSaveJob]);


    

    const applyForJob = async (jobId) => {
        try {
            const response = await axios.post(`${config.apiUrl}candidate/apply-job/${jobId}`, "", {
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            if (response.status === 201) {
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.detail);
            console.log(error);
        }
    };
    console.log(jobDetails)

    return (
        <>
            <div className="p-10 jb-details" style={{ backgroundColor: "#E9E9E9", height: "100%" }}>
                <div className="white-box">
                    <div className="flex items-center">
                        {companyLogoUrl ? (
                            <img src={companyLogoUrl} alt="Company Logo" className="company-logo"/>
                        ) : (
                            <img src="images/default.png" alt="Default Logo" className="company-logo"/>
                        )}
                        <div className="ml-2">
                            <h4>{jobDetails.title}</h4>
                            <p>{jobDetails.location} , {jobDetails.salary}</p>
                        </div>
                        <div className="ml-auto items-center flex">
                            <button
                                type="button"
                                className="theme-btn text-white text-sm mr-3"
                                onClick={() => applyForJob(jobDetails.id)}
                            >
                                 {jobDetails.applied_status === true? 'Applied' : 'Apply Now'}

                            </button>
                            <button type="button">
                                <img src="images/icons/share.svg" className="mr-5" alt="Share Icon" />
                            </button>
                            <button type="button">
                                <button type='button'>{jobDetails.is_saved ? <img src='images/icons/save2.svg' alt="save" onClick={() => unSaveJob(jobDetails.id)} /> : <img src='images/icons/save.svg' alt="save" onClick={() => saveJob(jobDetails.id)} />}</button>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-2">
                    <div className="col-span-8">
                        <div className="white-box job-content">
                            <h3>About this role</h3>
                            <p>{jobDetails.job_description}</p>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="white-box job-content">
                            <div className="mb-2">
                                <p>Job posted</p>
                                <h3>{jobDetails.job_posted_date}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Work Experience</p>
                                <h3>{jobDetails.work_experience}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Job Type</p>
                                <h3>{jobDetails.job_types}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Job Mode</p>
                                <h3>{jobDetails.job_modes}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Notice Period</p>
                                <h3>{jobDetails.notice_period}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Number of openings</p>
                                <h3>{jobDetails.openings}</h3>
                            </div>
                            <div className="mb-2">
                                <p>Skills</p>
                                <h3>
                                    {jobDetails.skills && jobDetails.skills.length > 0 ? (
                                        jobDetails.skills.map((skill, index) => (
                                            <span key={index}>{skill.name}, </span>
                                        ))
                                    ) : (
                                        <span>No skills mentioned</span>
                                    )}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
