import { useState } from "react";
import axios from "axios";
import config from "../../../config/config"; // adjust this path as necessary

const useJobSearch = (loginData) => {
    const [jobResults, setJobResults] = useState([]);
    const [error, setError] = useState(null);

    const handleSearch = async (filters) => {
        console.log("access token", loginData.access_token);
        const params = new URLSearchParams();
        Object.entries({
            job_title: filters.job_title,
            location: filters.location,
            job_types: filters.job_types.map(option => option.value),
            job_modes: filters.job_modes.map(option => option.value),
            notice_period: filters.notice_period.value,
            work_experience_min: filters.work_experience_min,
            work_experience_max: filters.work_experience_max,
            expected_salary_min: filters.expected_salary_min,
            expected_salary_max: filters.expected_salary_max,
            skill_set:filters.skill_set.length>0? JSON.stringify(filters.skill_set):"", // Send skill_set as a single JSON string array
        }).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(val => params.append(key, val));
            } else if (value !== undefined && value !== null && value !== "") {
                params.append(key, value);
            }
        });



        try {
            console.log("params", JSON.stringify(params));
            const response = await axios.get(`${config.apiUrl}candidate/find-jobs`, {
                params,
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            setJobResults(response.data.jobs);
            console.log("response-data", response.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError(error);
        }
    };

    return { jobResults, handleSearch, error };
};

export default useJobSearch;
