import React, { useState} from 'react';
import RightDrawer from './rightDrawer';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import config from '../../../config/config';
import Filters from './formFilter';


const Jobs = ({ data, filters, setFilters, handleSearch }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState({});
    const loginData = useSelector((state) => state.auth.Auth);
   
    // const [appliedJobs, setAppliedJobs] = useState({});
    const [companyLogos, setCompanyLogos] = useState({}); 
    const saveJob = async (jobId) => {
        try {
           
            console.log("access token", loginData.access_token);
            const response = await axios.post(
                `${config.apiUrl}candidate/save-job/${jobId}`,
                {}, 
                {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 201) {
               
             
                handleSearch(filters)
                toast.success(response.data.message);
               
            
            }
        } catch (error) {
            console.error("Error saving job:", error);
            
        }
       
        
    };
    const unSaveJob = async (jobId) => {
        try {
           
            console.log("access token", loginData.access_token);
            const response = await axios.post(
                `${config.apiUrl}candidate/unsave-job/${jobId}`,
                {}, // Empty body if no data is required
                {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 200) {
                
               
                handleSearch(filters)
                toast.success(response.data.message);
                
            }
        } catch (error) {
            console.error("Error saving job:", error);
            
        }
        
    };
    const toggleDrawer = (open, jobId) => async (event) => {
        console.log("toggling right drawer")
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);

        if (open && jobId) {
            await fetchJobDetails(jobId);
        }
    };
    const fetchJobDetails = async (jobId) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/job/${jobId}`, {
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            setSelectedJobDetails(response.data);
        } catch (error) {
            console.log('Error fetching job details:', error);
        }
    };
    // const applyForJob = async (jobId) => {
    //     try {
    //         const response = await axios.post(`${config.apiUrl}candidate/apply-job/${jobId}`, "", {
    //             headers: {
    //                 Authorization: `Bearer ${loginData.access_token}`,
    //             },
    //         });
    //         if (response.status === 201) {
    //             toast.success(response.data.message);
    //             setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));
               
    //         }
    //     } catch (error) {
    //         toast.error(error.response.data.detail);
    //         console.log(error);
    //     }
    // };
    return (
        <div className='flex flex-col md:flex-row align-center gap-5 mb-4 h-30 ml-4 mr-4 p-4'>
            <div className='flex-grow'>
                {data.length === 0 ? (
                    <p>No job results found.</p>
                ) : (
                    <>
                            <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} jobDetails={selectedJobDetails} fetchJobDetails={fetchJobDetails} />

                            <div className='default-cards flex flex-col gap-3 items-center'>
                                {data.map((item) => (
                                    <div className='mb-3 w-full border rounded-2xl p-4 bg-white' key={item.id}>
                                        <div className='flex items-center justify-between w-full'>
                                            {companyLogos[item.company_logo_id] ? (
                                                <img
                                                    src={companyLogos[item.company_logo_id]}
                                                    alt="Company logo"
                                                    className='w-12 h-12 object-cover rounded-full'
                                                />
                                            ) : (
                                                <img src='images/default.png' alt="Default logo" className='w-12 h-12 object-cover rounded-full' />
                                            )}
                                            <div className='ml-4 flex-grow'>
                                                <h4 className='text-xl font-semibold text-gray-800'>{item.title}</h4>
                                                <p className='text-gray-600'>{item.location} ₹{item.salary_min}-{item.salary_max} LPA</p>
                                            </div>
                                            <button
                                                className='flex items-center justify-center rounded-full h-9 w-9 bg-gray-300'
                                                onClick={toggleDrawer(true, item.id)}
                                            >
                                                <img src='images/icons/send-arrow.svg' className='' alt="View details" />
                                            </button>
                                        </div>
                                        <div className="ml-2 my-3 flex items-center gap-4">
                                            <div className='flex items-center text-sm text-gray-600'>
                                                <img src="images/icons/location.svg" alt="Location" className='mr-1' />
                                                <span>{item.job_modes}</span>
                                            </div>
                                            <div className='flex items-center text-sm text-gray-600'>
                                                <img src="images/icons/cap.svg" alt="Experience" className='mr-1' />
                                                <span>{item.work_experience}</span>
                                            </div>
                                            <div className='flex items-center text-sm text-gray-600'>
                                                <img src="images/icons/clock.svg" alt="Job type" className='mr-1' />
                                                <span>{item.job_types}</span>
                                            </div>
                                        </div>
                                        <div className='ml-3 text-gray-600 text-sm'>
                                            <span>{item.skill_set.map(s => s.name).join(" . ")}</span>
                                        </div>
                                        <div className='flex px-2 py-3 items-center'>
                                            <span className='flex items-center text-gray-600 text-sm'>
                                                <img src='images/icons/history.svg' className='mr-1' alt="Posted date" />
                                                {item.posted_date}
                                            </span>
                                            <div className='ml-auto flex items-center'>
                                                <button type='button' className='mr-4'>
                                                    <img src='images/icons/share.svg' alt="Share" />
                                                </button>
                                                <button type='button' onClick={() => item.is_saved ? unSaveJob(item.id) : saveJob(item.id)}>
                                                    <img
                                                        src={item.is_saved ? 'images/icons/save2.svg' : 'images/icons/save.svg'}
                                                        alt="Save"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                    </>
                )}
            </div>
            <div className='w-full md:w-1/4 md:ml-4 sticky top-0 bg-white rounded-2xl'>
                <Filters filters={filters} setFilters={setFilters} />
            </div>
        </div>


    );
}

export default Jobs