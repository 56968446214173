import React,{Suspense} from "react";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DrawerHeader from "../dashboard/DrawerHeader";
import CompanyInfo from "./companyInfo";
import Account from "./account";
import SocialMedia from "./socialMedia";
import ConfigurationStages from "../configuration/configrationStages";

// const AccountSetting  = React.lazy(()=>import('./accountSetting'))
// const ProfilePictureComponent = React.lazy(() => import('./ProfilePictureComponent'));



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const SettingTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent" >
                <DrawerHeader />
                {/* <h2 className="menu-heading">Setting</h2> */}
                <Box sx={{ width: '100%' }} className="setting-tabs">
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }} className="hirree-tabs">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                            <Tab label="Company Info" {...a11yProps(0)} />
                            <Tab label="Configure Hiring Stages" {...a11yProps(1)} />
                            <Tab label="Account Settings" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                       <CompanyInfo/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <ConfigurationStages/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                    <Account/>
                    </CustomTabPanel>

                </Box>
            </Box>
        </>
    )
}
export default SettingTabs;