import React, { useState,useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import RightDrawer from './rightDrawer';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import useJobSearch from './useJobSearch'
import Jobs from './Jobs';
import config from "../../../config/config";

const states = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat',
    'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh',
    'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh',
    'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Jammu and Kashmir', 'Ladakh',
    'Lakshadweep', 'Puducherry'
];

const JobSearch = () => {
    const [searchTriggered, setSearchTriggered] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const loginData = useSelector((state) => state.auth.Auth);
    const [filters, setFilters] = useState({
        job_title: '',
        job_types: [],
        job_modes: [],
        location: '',
        notice_period: '',
        work_experience_min: '',
        work_experience_max: '',
        expected_salary_min: '',
        expected_salary_max: '',
        skill_set: [],
    });

    const defaultFilters = {
        job_title: filters.job_title,
        location: filters.location,
        job_types: [],
        job_modes: [],
        notice_period: '',
        work_experience_min: '',
        work_experience_max: '',
        expected_salary_min: '',
        expected_salary_max: '',
        skill_set: [],
    };

    const [allJobTitles, setAllJobTitles] = useState([]);  // Store all job titles
    const [suggestions, setSuggestions] = useState([]);  // Store filtered suggestions
    const [loading, setLoading] = useState(false);  // To handle loading state

    // Fetch all job titles once when the component is mounted
    useEffect(() => {
        handleSearch(filters);
    }, [filters]);

    useEffect(() => {
        const fetchJobTitles = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${config.apiUrl}candidate/job-titles`, {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                });

                // Store the job titles in state
                setAllJobTitles(response.data);
                console.log(response.data)
            } catch (error) {
                console.error("Error fetching job titles:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobTitles();
    }, []);  // Empty dependency array ensures it runs only once

    // Handle changes to the input field
  
    const handleTitleChange = (event) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });

        if (value.trim() === "") {
            setSuggestions([]);  // Clear suggestions when input is empty
            return;
        }

        // Filter the job titles based on the keyword entered
        const filteredSuggestions = allJobTitles.filter((title) =>
            title.toLowerCase().includes(value.toLowerCase())  // Case-insensitive search
        );
        setSuggestions(filteredSuggestions);  // Update the suggestions state
    };

    // Handle selection of a suggestion
    const handleSelectSuggestion = (title) => {
        setFilters({ ...filters, job_title: title });
        setSuggestions([]);  // Clear suggestions after selection
    };
    const { jobResults, handleSearch } = useJobSearch(loginData);
    
    const handleJobSearch = () => {
        handleSearch(filters);
        setSearchTriggered(true);// Pass filters here
        console.log("jobs in job serach", jobResults);
    };
    const handleChange = async(event) => {
       
        const { name, value } = event.target;  
        setFilters(prev => ({
            ...(name === "location"||"job_title" ? defaultFilters : prev), 
            [name]: value
        }));

       
       
    }
    const toggleDrawer = (open) => async (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };
    return <>
        <div className="bg-gray-100 w-full h-full m-0 p-0" >
        <div className="flex flex-col w-full bg-gray-80 p-4">
            {/* <div className="w-full shadow-lg p-5"> */}
            <Toaster />
            <RightDrawer name='filters' open={isDrawerOpen} toggleDrawer={toggleDrawer} filters={filters} setFilters={setFilters} />
                <h2 className="menu-heading pl-0 lg:mb-4">Find Jobs</h2>
            <div className="flex align-center gap-5 mb-4 bg-white h-30 ml-4 mr-4 p-4 shadow-2xl shadow-gray-100">
                <div className="relative w-1/4">
                    <div className="flex items-center border-b-2">
                        <SearchIcon fontSize="medium" />
                        <input
                            type="text"
                            name="job_title"
                            placeholder="Job title or keyword"
                            value={filters.job_title}
                            onChange={handleTitleChange}
                            className="p-2 w-full outline-none"
                        />
                    </div>

                        {suggestions && suggestions.length > 0 && !searchTriggered &&(
                        <ul className="absolute mt-1 w-full bg-white border border-gray-300 shadow-lg z-10 rounded-medium">
                            {loading && <li className="p-2">Loading...</li>}
                            {suggestions.map((title, index) => (
                                <li
                                    key={index}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleSelectSuggestion(title)}
                                >
                                    {title}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='flex flex-row justify-items-center items-center'>
                <FmdGoodOutlinedIcon fontSize="medium" />
                    <select
                    name="location"
                    value={filters.location}
                    onChange={handleChange}
                    className="mx-2 p-2 border-b-2"
                >
                    <option value="" hidden>Select Location</option>
                    {states.map((state, index) => (
                        <option key={index} value={state}>{state}</option>
                    ))}
                    </select>
                </div>
                {/* <button
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full flex items-center space-x-2"
                    onClick={toggleDrawer(true)}
                >
                    <span><SortOutlinedIcon fontSize="medium" /></span>
                    <span>Filter</span>
                </button> */}
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded-full ml-2"
                    onClick={handleJobSearch}
                    >
                        Search
                    </button>
                </div>
            <div className="ml-5 mr-4">
                <h3 className="text-xs text-gray-400">Popular: UI Designer, UX Researcher, Android, Admin</h3>
                    
                </div>
            
        </div>
       
            {searchTriggered && <Jobs data={jobResults}  filters={filters} setFilters={setFilters} handleSearch={handleSearch}/>}
       </div>
        </>
};

export default JobSearch;