import React, { useState } from 'react';
import CandidateSignUp from './CandidateForm';
import EmployerSignup from './EmployerSignup';
import LeftImage from '../design/LeftImage';
import { Link, useLocation } from 'react-router-dom';
import config from '../../config/config';

function Signup() {
  const location = useLocation();
  const initialType = location.state?.userType || 'candidate';  // Get the state passed from login page
  const [showComponent, setshowComponent] = useState(initialType);
  const [activeBtn, setActiveBtn] = useState(initialType);

  const handleCandidate = () => {
    setshowComponent('candidate');
    setActiveBtn('candidate');
  }

  const handleEmployer = () => {
    setshowComponent('employer');
    setActiveBtn('employer');
  }

  return (
    <div className='bg-[#F3F9FC] forms'>
      <div className="mx-auto sm:max-w-full sm:max-w-[1200px] signup">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-7">
            <LeftImage />
          </div>
          <div className="bg-white p-md-4 pt-10 pr-20 pl-10 col-span-5">
            <div className=''>
              <div className='flex justify-center items-center'>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'candidate' ? 'active' : ''}`} onClick={handleCandidate}>Job Seeker</button>
                <button type="button" className={`me-2 bordered-btn theme-btn ${activeBtn === 'employer' ? 'active' : ''}`} onClick={handleEmployer}>Company</button>
              </div>
            </div>
            {showComponent === 'employer' && <EmployerSignup />}
            {showComponent === 'candidate' && <CandidateSignUp />}

           <div className='my-5 relative'>
            <span className='or'>or</span>
           </div>
            <div className="footer-content ">
              <p className="py-3">Already have an account?  <Link to="/login" state={{ userType: activeBtn }}> Log In</Link></p>
                <p>By clicking ‘Sign Up, you acknowledge that you have read and accept the <Link to={`${config.baseUrl}terms-and-conditions`}>Terms of Service </Link>
                and <Link to={`${config.baseUrl}privacy-policy`}> Privacy Policy.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
