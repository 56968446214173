import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import JobDetailsFrom from './JobDetailsFrom';
// import JobDescriptionForm from './jonDescriptionForm';
import JobPreviewForm from './jobPreviewForm';
import axios from 'axios';
import config from '../../../config/config';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import AppBar from '../dashboard/Appbar';

const steps = ['Job Details', 'Job Preview'];

export default function PostJobForm({  initialData }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const loginData = useSelector((state) => state.auth.Auth);


    // Define errors state for each step
    const [errors, setErrors] = React.useState({
        jobDetailsErrors: {},
        jobDescriptionErrors: {},
        jobPreviewErrors: {},
    });
    // console.log("mdskjdasjkdka",initialData)
    const [formData, setFormData] = React.useState({
        job_title: initialData?.title || "",
        job_types: initialData?.job_types || [],
        job_modes: initialData?.job_modes || [],
        location: initialData?.location || "",
        work_experience_min: initialData?.experience?.split('-')[0] || "",
        work_experience_max: initialData?.experience?.split('-')[1] || "",
        notice_period: initialData?.notice_period || "",
        working_time: {
            start_time: initialData?.working_time?.start_time || "",
            end_time: initialData?.working_time?.end_time||""
        },
        expected_salary_min: initialData?.salary_min || "",
        expected_salary_max: initialData?.salary_max || "",
        job_description: initialData?.job_description || "",
        skill_set: initialData?.skill_set || [],
        department: initialData?.department || "",
        education: initialData?.education || "",
        openings: initialData?.openings || "",
        work_experience_min_unit: initialData?.work_experience_min_unit || "months",
        work_experience_max_unit: initialData?.work_experience_max_unit || "months",
        salary_type: initialData?.salary_type|"",
        salary_currency: initialData?.salary_currency||"INR",
        is_fresher_role: initialData?.is_fresher_role || false
    });

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // Validation for Job Details form
    const validateJobDetails = (formData) => {
        let errors = {};
        // let hasToastError = false;

        if (!formData.job_title) errors.job_title = "Job title is required";
        if (formData.job_types.length === 0) errors.job_types = "Job type is required";
        if (!formData.location) errors.location = "Location is required";
        if (formData.job_modes.length === 0) errors.job_modes = "Job Mode is required";
        if (!formData.notice_period) errors.notice_period = "Notice period is required";
        if (!formData.is_fresher_role) {
            if (formData.work_experience_min === '' || formData.work_experience_min === null || formData.work_experience_min === undefined) {
                errors.work_experience_min = "Exp. required";
            }

            if (formData.work_experience_max === '' || formData.work_experience_max === null || formData.work_experience_max === undefined) {
                errors.work_experience_max = "Exp. required";
            }

            //Check if Max is less than Min, and handle toast notification
            // else if (formData.work_experience_min_unit === 'months' && formData.work_experience_min < 6) {
            //     toast.error("Minimum Experiece should be atleast 6 months", {
            //         autoClose: 5000,
            //     });
            //     errors.work_experience_min = ""; // Keep the field invalid
            // }
            else if (formData.work_experience_max && formData.work_experience_min && formData.work_experience_min==="years" &&
                formData.work_experience_max < formData.work_experience_min) {
                toast.error("Max work experience should be greater than Min work experience", {
                    autoClose: 5000,
                });
                errors.work_experience_max = ""; // Keep the field invalid
            }
        }
        // if (!formData.expected_salary_max) errors.expected_salary_max = "Expected salary is required";
        // if (!formData.expected_salary_min) errors.expected_salary_min = "Expected salary is required";
        if (!formData.department) errors.department = "Department name is required";
        if (!formData.openings) errors.openings = "Number of openings required";
        if (!formData.education) errors.education = "Education is required";
        if (!formData.job_description) errors.job_description = "Job description is required";
        if (formData.skill_set.length === 0) errors.skill_set = "At least one skill is required";
        if (formData.working_time?.start_time === '') errors.start_time = "start time is required";
        if (formData.working_time?.end_time === '') errors.end_time = "End time is required";
        if (!formData.salary_currency) errors.salary_currency = "salary currency is required";
        if (!formData.salary_type) errors.salary_type = "salary type is required";
        if (!formData.expected_salary_min) errors.expected_salary_min = "minimum salary offered is required";
        if (!formData.expected_salary_max) errors.expected_salary_max = "maximum salary offered is required";
        // if (!formData.work_experience_min_unit) errors.work_experience_min_unit = "please select ";
        // if (!formData.work_experience_max_unit) errors.work_experience_max_unit = "please select ";
        return errors;
    };

    // Validation for Job Description form
    const validateJobDescription = (formData) => {
        let errors = {};
        if (!formData.job_description) errors.job_description = "Job description is required";
        if (formData.skill_set.length === 0) errors.skill_set = "At least one skill is required";
        // Add other necessary validations
        return errors;
    };

    const handleNext = () => {
        let newSkipped = skipped;
        let validationErrors = {};
        console.log(JSON.stringify(formData, null, 2));
        // Run validation depending on the active step
        if (activeStep === 0) {
            validationErrors = validateJobDetails(formData);
            setErrors((prevErrors) => ({ ...prevErrors, jobDetailsErrors: validationErrors }));
           
        } else if (activeStep === 1) {
            validationErrors = validateJobDescription(formData);
            setErrors((prevErrors) => ({ ...prevErrors, jobDescriptionErrors: validationErrors }));
        }
        console.log(JSON.stringify(validationErrors, null, 2));
        // If validation fails, do not proceed to the next step
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({
            job_title: "",
            job_types: [],
            job_modes: [],
            location: "",
            work_experience: "",
            notice_period: "",
            expected_salary_min: "",
            work_experience_max_unit: "",
            expected_salary_max: "",
            job_description: "",
            skill_set: [],
            department: "",
            openings: "",
            education: "",
            is_fresher_role: false,
            salary_type: "Monthly",
            salary_currency: "INR",
            working_time: {
                start_time: '', 
                end_time: ''
            }
        });
    };

    const handleFinish = async (e) => {
        e.preventDefault();
        try {
            if (initialData && initialData.id) {
                console.log(JSON.stringify(formData, null, 2));
                const response = await axios.patch(`${config.apiUrl}employer/jobs/${initialData.id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
                if (response.status === 200) {
                    toast.success("Job updated successfully");
                    window.location.href = `${config.employerUrl}job-listing`;
                }
            } else {
                const response = await axios.post(`${config.apiUrl}employer/jobs`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loginData.access_token}`,
                    },
                });
                if (response.status === 201) {
                    toast.success(response.data.message);
                    // setTimeout(() => { window.location.reload() }, [500]);
                    window.location.href = `${config.employerUrl}job-listing`;
                }
            }

        } catch (error) {
            console.error(error);
            if (error.response && error.response.data) {
                toast.error(error.response.data.detail);
            }
        }
    };

    const fetchJobs = async () => {
        try {

            const response = await axios.get(`${config.apiUrl}employer/jobs/${initialData.id}`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                },
            });

            if (response.status === 200) {
                setFormData(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchJobs()
    }, [loginData.access_token]);

    console.log(formData)
    // Render each step's content
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return <JobDetailsFrom formData={formData} setFormData={setFormData} errors={errors.jobDetailsErrors} setErrors={setErrors} />;
            // case 1:
            //     return <JobDescriptionForm formData={formData} setFormData={setFormData} errors={errors.jobDescriptionErrors} setErrors={setErrors}/>;
            case 1:
                return <JobPreviewForm formData={formData} setFormData={setFormData} errors={errors.jobPreviewErrors} setErrors={setErrors} />;
            default:
                return 'Unknown step';
        }
    };


    return (
        <>
            <AppBar />
            <Box className="post-form pb-10">
                {/* <div className='flex justify-end'><CloseIcon onClick={handleCloseFrom} style={{ cursor: "pointer" }} /></div> */}
                <Stepper activeStep={activeStep} className='job-stepper'>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption"></Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            {/* //content */}
                            {renderStepContent(activeStep)}     
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 0, width: '100%'}}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <div>
                                <button
                                    className='theme-btn text-white'
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    style={{ marginRight: "10px", display: activeStep === 0 ? 'none' : 'inline-flex' }}
                                >
                                    Back
                                    </button>
                                </div>
                                <div>
                                <button
                                    type='button'
                                    onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
                                    className='theme-btn text-white'
                                    style={{ alignSelf: 'flex-end' }}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </button>
                                </div>
                            </Box>
                        </React.Fragment>
                )}
            </Box>
        </>
    );
}
