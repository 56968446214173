import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import toast, { Toaster } from 'react-hot-toast';
import { scheduleInterviewAPI, updateInterviewAPI } from '../../utils/employer.api/employer.api.services';
import * as Yup from 'yup';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '10px',
  left: '50%',
  transform: 'translate(-50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: "16px",

  zIndex:1
};

export default function InterviewSchedule({ openModal, closeModal, jobId, applicantId,
   token,applicantData, applicantStages,refreshInterviews ,interviewdetails,interviewId}) {
  // const [currentStage, setCurrentStage] = useState(applicantData?.stage);
  const [interviewStage, setInterviewStage] = useState('');
  const [interviewerName, setInterviewerName] = useState('');
  const [interviewDate, setInterviewDate] = useState('');
  const [interviewType, setInterviewType] = useState('');
  const [joiningLink, setJoiningLink] = useState('');
  const [location, setLocation] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [interviewTime, setInterviewTime] = useState({
    start_time: '',
    end_time: ''
  });

useEffect(() => {
  
  if (interviewId) {
    // If interviewId is provided, load the interview details for editing
    const details = interviewdetails?.find(item => item.id === interviewId);
    if (details) {
      setInterviewerName(details.interviewer_name || '');
      setInterviewDate(details.interview_date || '');
      setInterviewType(details.interview_type || '');
      setJoiningLink(details.joining_link || '');
      setLocation(details.location || '');
      setPhone(details.phone_number || '');
      setInterviewTime({
        start_time: details.interview_time?.start_time || '',
        end_time: details.interview_time?.end_time || ''
      });
    }
  } else {
    // If no interviewId (new interview), reset the form to default state
    setInterviewerName('');
    setInterviewDate('');
    setInterviewType('');
    setJoiningLink('');
    setLocation('');
    setPhone('');
    setInterviewTime({
      start_time: '',
      end_time: ''
    });
  }
}, [interviewId, interviewdetails]);
console.log(interviewdetails)

  let payload = {
    current_stage: applicantData?.stage,
    applicant_id: applicantId,
    interview_stage: interviewStage,
    interviewer_name: interviewerName,
    interview_date: interviewDate,
    interview_time: interviewTime,
    interview_type: interviewType,
    joining_link: joiningLink,
    phone_number: phone,
    location: location,
    interview_time: {
      start_time: interviewTime.start_time,
      end_time: interviewTime.end_time
    },
  };
  const resetFields = () => {
    setInterviewStage('');
    setInterviewerName('');
    setInterviewDate('');
    setInterviewTime('');
    setInterviewType('');
    setJoiningLink('');
    setLocation('');
    setPhone('');
  };
  const validationSchema = Yup.object({
    current_stage: Yup.string(),
    applicant_id: Yup.string(),
    interview_stage: Yup.string().required('Interview stage is required'),
    interviewer_name: Yup.string().required('Interviewer name is required'),
    interview_date: Yup.date()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .required('Interview date is required')
      .typeError('Interview date is required'),
    interview_time: Yup.string().required('Interview time is required'),
    interview_type: Yup.string().required('Please select an Interview Mode'),
    joining_link: Yup.lazy((value, { parent }) =>
      parent.interview_type !== 'Video call'
        ? Yup.string().notRequired()
        : Yup.string()
          .required('Meeting link is required')
          .matches(
            /^(https?:\/\/)?(meet\.google\.com\/|zoom\.us\/j\/|teams\.microsoft\.com\/|webex\.com\/meet\/)/,
            'Please enter a valid meeting link (e.g., Google Meet, Zoom, Teams, Webex)'
          )
    ),
    location: Yup.lazy((value, { parent }) =>
      parent.interview_type !== 'In-office'
        ? Yup.string().notRequired()
        : Yup.string().required('location is required')),
    phone_number: Yup.lazy((value, { parent }) =>
      parent.interview_type !== 'Phone'
        ? Yup.string().notRequired() // Not required if interview type is not 'Phone'
        : Yup.string()
          .required('Phone number is required') // Required if interview type is 'Phone'
           // Checks for 10-digit format if provided
    ),
    interview_time: Yup.object({
      start_time: Yup.string()
        .required('Start time is required'),
      end_time: Yup.string()
        .required('End time is required')
        .test('is-greater', 'End time must be after start time', function (value) {
          const { start_time } = this.parent;
          if (start_time && value) {
            const startTime = new Date(`1970-01-01T${start_time}:00`);
            const endTime = new Date(`1970-01-01T${value}:00`);
            return endTime > startTime;
          }
          return true; // If either is missing, do not validate this condition
        })
    }),
    
  });
  const handleTimeChange = (newTime, field) => {
    if (newTime) {
      const formattedTime = dayjs(newTime).format('HH:mm'); // Format time to HH:mm
      setInterviewTime((prevTime) => ({
        ...prevTime,
        [field]: formattedTime,
      }));
      console.log(`Formatted ${field} for payload:, ${formattedTime}`);
    } else {
      setInterviewTime((prevTime) => ({
        ...prevTime,
        [field]:'',
      }));
    }
    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };

      // Remove specific error if field is filled
      if (newTime) {
        delete updatedErrors[`interview_time.${field}`]; // Correct field reference
      }

      return updatedErrors;
    });
  };
  // useEffect(() => {
  //   // Perform any action based on updated errors
  //   console.log('Updated errors:', errors);
  // }, [errors]);
  const handleChange = async(e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === 'interview_stage') {
      setInterviewStage(value);
    }
    else if (name === 'interviewer_name') {
      setInterviewerName(value);
    }
    else if (name ==='interview_date') {
      setInterviewDate(value);
    } else if (name ==='interview_time'){
      setInterviewTime(value);
    }
    else if (name === 'joining_link') {
      setJoiningLink(value);
    }
    else if (name === 'phone_number') {
      setPhone(value);
    }
    else if (name == 'location') {
      setLocation(value);
    }
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    }
    catch (error) {
      console.log(error);
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
    }
    

  }
  const handleSubmit = async () => {
    try {
      console.log(payload);
      await validationSchema.validate(payload, { abortEarly: false });
      let response;
      // Conditionally call the correct API based on whether it's an update or new schedule
      console.log(jobId, payload)
      if (interviewId) {
        response = await updateInterviewAPI( interviewId, payload, token);
      } else {
        response = await scheduleInterviewAPI(jobId, payload, token);
      }
      
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        await refreshInterviews();
        setTimeout(() => {
          closeModal();
        }, 1000);
        resetFields();
        setErrors({});
      }
    } catch (error) {
      console.log(error);
  
      if (error.name === 'ValidationError') {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        console.log(JSON.stringify(validationErrors, null, 2));
        setErrors(validationErrors);
      } else {
        // Add checks to prevent accessing undefined properties
        if (error.response && error.response.data && error.response.data.detail) {
          if (typeof error.response.data.detail === 'string') {
            // Single error message
            toast.error(error.response.data.detail);
          } else if (Array.isArray(error.response.data.detail)) {
            // Array of errors
            const validationErrors = {};
            error.response.data.detail.forEach(err => {
              const field = err.loc[1];
              validationErrors[field] = err.msg;
            });
            setErrors(validationErrors);
          }
        } else {
          // Fallback error message if error structure is unexpected
          toast.error("Something went wrong");
        }
      }
    }
  };
  
  if (interviewType === 'Video call') {
    payload.joining_link = joiningLink;
    // Remove phone and location
    delete payload.phone_number;
    delete payload.location;
  } else if (interviewType === 'Phone') {
    payload.phone_number = phone;
    // Remove joining link and location
    delete payload.joining_link;
    delete payload.location;
  } else if (interviewType === 'In-office') {
    payload.location = location;
    // Remove phone and joining link
    delete payload.phone_number;
    delete payload.joining_link;
  }
const handleCloseModal =() =>{
  resetFields()
  setErrors({})
  closeModal();
}
// console.log("stages",applicantStages)
  return (
    <div>
      <Modal
        open={openModal}
        top
        // onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Toaster/>
          <div className='px-10 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
            <h2 className='heading2'>Schedule Interview</h2>
          </div>
          <div className='modal-form px-10 py-5'>
            <form className='form-theme'>
              {/* Input fields */}
              <div className='grid grid-cols-2'>
                <div className='relative mb-4 mr-3'>
                  <label className='mb-1'>Current Stage</label>
                  <input type='input' className='w-full border border-gray-300' value={applicantData?.stage} readOnly/>
                </div>
                <div className='relative mb-4 mr-3'>
                  <label className='mb-1'>Interview Stage<span className="text-red-500">*</span></label>
                  <select
                    className="w-full border border-gray-300"
                    name="interview_stage"
                    value={interviewStage}
                    onChange={handleChange}
                    placeholder="Select stage"
                  >
                    <option value="" disabled hidden>Interview Stage</option>
                    {applicantStages
                      .slice(applicantStages.findIndex(stage => stage.name === applicantData?.stage) + 1)
                      .map(stage => (
                        <option key={stage.name} value={stage.name}>{stage.name}</option>
                      ))}
                  </select>
                  {errors["interview_stage"] && <span className="text-red-500 text-sm">{errors["interview_stage"]
                  }</span>}
                </div>
                <div className='relative mb-4 mr-3'>
                  <label className='mb-1'>Interviewer<span className="text-red-500">*</span></label>
                  <select
                    className="w-full border border-gray-300"
                    value={interviewerName}
                    name="interviewer_name"
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden>Select Interviewer</option>
                    <option value="Shreyansh Singh">Shreyansh Singh</option>
                  </select>
                  {errors["interviewer_name"] && <span className="text-red-500 text-sm">{errors["interviewer_name"]
                  }</span>}
                </div>
                <div className='relative mb-4 mr-3'>
                  <label className='mb-1'>Interview Date<span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="interview_date"
                    className="w-full border border-gray-300"
                    value={interviewDate}
                    min={new Date().toISOString().split("T")[0]}  // Disable previous dates
                    onChange={handleChange}
                  />
                  {errors["interview_date"] && <span className="text-red-500 text-sm">{errors["interview_date"]
                  }</span>}
                </div>
               
                <div className="mb-3">
                  <label htmlFor="interview_time" className="block mb-3">
                    Interview Time<span className="text-red-500">*</span>
                  </label>
                  <div className="flex w-full gap-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="flex flex-col gap-1">
                        <TimePicker
                          value={interviewTime?.start_time ? dayjs(interviewTime?.start_time, 'HH:mm') : null}
                          onChange={(newTime) => handleTimeChange(newTime, 'start_time')}
                          ampm
                          renderInput={(params) => <TextField {...params} />}
                        />
                        {errors["interview_time.start_time"] && (
                          <p className="text-xs text-red-500">{errors["interview_time.start_time"]}</p>
                        )}
                      </div>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className={`flex flex-col gap-1 ${interviewTime?.end_time ? '' : 'text-gray-400'}`}>
                        <TimePicker
                          value={interviewTime?.end_time ? dayjs(interviewTime?.end_time, 'HH:mm') : null}
                          onChange={(newTime) => handleTimeChange(newTime, 'end_time')}
                          ampm
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  '&::placeholder': {
                                    fontWeight: '500', // Adjust font weight
                                    color: 'gray',     // Adjust color as needed
                                  },
                                },
                              }}
                            />
                          )}
                        />
                        {errors["interview_time.end_time"] && (
                          <p className="text-xs text-red-500">{errors["interview_time.end_time"]}</p>
                        )}
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              
              <div className='my-4'>
                <button type='button' className={`py-2 px-3 mr-3 rounded-full text-sm text-white inline-flex items-center ${interviewType === 'Video call' ? 'bg-blue-500' : 'bg-[#C4C4C4]' }`}
                  onClick={() => setInterviewType('Video call')}>
                  <img src='images/icons/video.svg' alt='video' className='mr-2' />Video call
                </button>
                <button type='button' className={`py-2 px-3 mr-3 rounded-full text-sm text-white inline-flex items-center ${interviewType === 'Phone' ? 'bg-blue-500' : 'bg-[#C4C4C4]'}`}
                  onClick={() => setInterviewType('Phone')}>
                  <img src='images/icons/phone-light.svg' alt='phone' className='mr-2' />Phone
                </button>
                <button type='button' className={`py-2 px-3 mr-3 rounded-full text-sm text-white inline-flex items-center ${interviewType === 'In-office' ? 'bg-blue-500' : 'bg-[#C4C4C4]' }`}
                  onClick={() => setInterviewType('In-office')}>
                  <img src='images/icons/company-light.svg' alt='company' className='mr-2' />In-office
                </button>
                
              </div>
              <span>{interviewType==='' && errors["interview_type"] && <span className="text-red-500 text-sm">{errors["interview_type"]
              }</span>}</span>
              {/* Conditionally render input fields based on interview type */}
              {interviewType === 'Video call' && (
                <div className='relative mb-4'>
                  <label>Meeting Link</label>
                  <input
                    type='  '
                    placeholder='https://meet.google.com/otd-dckc-hkb'
                    className='w-full border border-gray-300'
                    value={joiningLink}
                    name='joining_link'
                    onChange={handleChange}
                  />
                  {errors["joining_link"] && <span className="text-red-500 text-sm">{errors["joining_link"]
                  }</span>}
                </div>
              )}

              {interviewType === 'Phone' && (
                <div className='relative mb-4'>
                  <label>Phone Number</label>
                  <input
                    type='text'
                    placeholder='+1234567890'
                    className='w-full border border-gray-300'
                    value={phone}
                    name='phone_number'
                    onChange={handleChange}
                  />
                  {errors["phone_number"] && <span className="text-red-500 text-sm">{errors["phone_number"]
                  }</span>}
                </div>
                
              )}

              {interviewType === 'In-office' && (
                <div className='relative mb-4'>
                  <label>Location</label>
                  <input
                    type='text'
                    placeholder='Office Location'
                    className='w-full border border-gray-300'
                    value={location}
                    name='location'
                    onChange={handleChange}
                  />
                  {errors["location"] && <span className="text-red-500 text-sm">{errors["location"]
                  }</span>}
                </div>
                
              )}

              <div className='flex justify-end'>
                <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={handleCloseModal}>
                  <span className="color-text">Cancel</span>
                </button>
                <button type="button" className="theme-btn bordered-btn py-2" onClick={handleSubmit}>
                  <span className="color-text">Schedule</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}