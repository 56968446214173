import React, { useEffect, useState } from 'react';
import RightDrawer from './rightDrawer';
import axios from 'axios';
import { getSavedJobs} from '../../../utils/candidate.api/candidate.api.services';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import config from '../../../config/config';


export default function RecomendationContent() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState({});
    const loginData = useSelector((state) => state.auth.Auth);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [appliedJobs, setAppliedJobs] = useState({});
    const [companyLogos, setCompanyLogos] = useState({}); // Store company logos
    const [notFoundErr, setNotFoundErr] = useState()

 
    const unSaveJob = async (jobId) => {
        try {
            console.log("access token", loginData.access_token);
            const response = await axios.post(
                `${config.apiUrl}candidate/unsave-job/${jobId}`,
                {}, // Empty body if no data is required
                {
                    headers: {
                        Authorization: `Bearer ${loginData.access_token}`,
                    },
                }
            );

            if (response.status === 200) {
                // console.log("Job saved successfully!", response.data);
                toast.success(response.data.message);
                fetchSavedJobs();
            }
        } catch (error) {
            console.error("Error saving job:", error);
            // Handle error - you might want to display an error message to the user
        }
    };
    const toggleDrawer = (open, jobId) => async (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);

        if (open && jobId) {
            await fetchJobDetails(jobId);
        }
    };

    const fetchJobDetails = async (jobId) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/job/${jobId}`, {
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            setSelectedJobDetails(response.data);
        } catch (error) {
            console.log('Error fetching job details:', error);
        }
    };

    const fetchCompanyLogo = async (logoId) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/company-logo/${logoId}`, {
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
                responseType: 'blob',
            });
            const logoUrl = URL.createObjectURL(response.data);
            setCompanyLogos((prev) => ({ ...prev, [logoId]: logoUrl }));
        } catch (error) {
            console.log('Error fetching company logo:', error);
            return null;
        }
    };
    useEffect(() => {
        data.forEach((job) => {
            if (job.company_logo_id && !companyLogos[job.company_logo_id]) {
                fetchCompanyLogo(job.company_logo_id);
            }
        });

        return () => {
            Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up blob URLs
        };
    }, [data, companyLogos]);

    const fetchCompanyLogosBatch = async (logoIds) => {
        try {
            const response = await axios.post(`${config.apiUrl}company/logos/batch`, { ids: logoIds });
            return response.data;
        } catch (error) {
            console.error("Error fetching company logos:", error);
            return {}; // Return an empty object in case of error
        }
    };

    const fetchSavedJobs = async () => {
        try {
            const response = await getSavedJobs(loginData.access_token);
            setData(response.data);

            // Collect all company_logo_ids for jobs that need logos
            const logoIds = response.data
                .filter(job => job.company_logo_id && !companyLogos[job.id])
                .map(job => job.company_logo_id);

            // Fetch logos in batch if there are any
            if (logoIds.length > 0) {
                const logos = await fetchCompanyLogosBatch(logoIds);

                // Create a map from logo ID to logo URL
                const logoMap = response.data.reduce((acc, job) => {
                    if (logos[job.company_logo_id]) {
                        acc[job.id] = logos[job.company_logo_id];
                    }
                    return acc;
                }, {});

                // Update company logos state
                setCompanyLogos(prevLogos => ({ ...prevLogos, ...logoMap }));
            }

            setLoading(true);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setNotFoundErr(error.response.data.detail);
                }
            } else {
                console.log(error);
            }
        } finally {
            setLoading(false);
        }
    };


    const applyForJob = async (jobId) => {
        try {
            const response = await axios.post(`${config.apiUrl}candidate/apply-job/${jobId}`, "", {
                headers: {
                    Authorization: `Bearer ${loginData.access_token}`,
                },
            });
            if (response.status === 201) {
                toast.success(response.data.message);
                setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));
                fetchSavedJobs()
            }
        } catch (error) {
            toast.error(error.response.data.detail);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSavedJobs();
    }, [loginData.access_token]);

    return (
        <>
            <div>
                <Toaster />
                <RightDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} jobDetails={selectedJobDetails} />
                <h2 className='menu-heading pl-0 lg:mb-4'>Saved Jobs</h2>
                {notFoundErr}
                <div className='default-cards'>
                    {!loading ? (
                        <div className='grid md:grid-cols-2 lg:grid-cols-2 gap-4'>
                            {data.map((item) => (
                                <div className='card1 mb-3' key={item.id}>
                                    <div className='card-data'>
                                        <div className='flex items-center gap-x-4'>
                                            {/* Display company logo if available */}
                                            {companyLogos[item.company_logo_id] ? (
                                                <img src={companyLogos[item.company_logo_id]} alt="Company logo" className='company-logo' />
                                            ) : (
                                                <img src='images/default.png' alt="Default logo" className='company-logo' />
                                            )}
                                            <div>
                                                <h4>{item.title}</h4>
                                                <p>{item.location} {item.salary}</p>
                                            </div>
                                            <button className='md:p-1 ml-auto lg:p-2 flex items-center justify-center lg:h-10 lg:w-10 lg:h-8 lg:w-8 bg-[#E7E7E7] rounded-full'
                                                onClick={toggleDrawer(true, item.id)}> {/* Pass job ID */}
                                                <img src='images/icons/send-arrow.svg' className='brand-icon' alt="view details" />
                                            </button>
                                        </div>
                                        <div className="my-3 type-btns flex items-center">
                                            <div><img src="images/icons/location.svg" alt="location" className='mr-1' />{item.job_mode}</div>
                                            <div><img src="images/icons/cap.svg" alt="experience" className='mr-1' />{item.experience}</div>
                                            <div><img src="images/icons/clock.svg" alt="job type" className='mr-1' />{item.job_type}</div>
                                        </div>
                                        <div className="flex justify-between ">
                                            <span><img src='images/icons/history.svg' className='md:mr-1 lg:mr-2 inline-block' alt="posted date" />{item.posted_date}</span>
                                            <span>Np : {item.notice_period}</span>
                                        </div>
                                    </div>
                                    <div className='foot-content items-center flex px-6 py-3'>
                                        <button
                                            type="button"
                                            className={`theme-btn text-white text-sm ${appliedJobs[item.id] ? 'bg-gray-400' : 'bg-primary'}`}
                                            onClick={() => applyForJob(item.id)}
                                            disabled={appliedJobs[item.id]}
                                        >
                                            {item.applied_status === true ? 'Applied' : 'Apply Now'}
                                        </button>
                                        <div className='ml-auto'>
                                            <button type='button'><img src='images/icons/share.svg' className='mr-4' alt="share" /></button>
                                            <button type='button'><img src='images/icons/save2.svg' alt="save" onClick={()=>{unSaveJob(item.id)}} /></button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : <span>Loading...</span>}
                </div>
            </div>
        </>
    );
}
