import React, { useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from "react-router-dom";
import { getInterviewPage } from "../../../utils/candidate.api/candidate.api.services";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InterviewContent = () => {
    const loginData = useSelector((state) => state.auth.Auth);
    const [value, setValue] = React.useState(0);
    const [logoUrls, setLogoUrls] = useState({});
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [interviewData, setInterviewData] = useState({
        stats: {
            interviews_for_today: 0,
            pending_interviews: 0,
            completed_interviews: 0
        },
        upcoming_interviews: [],
        completed_interviews: []
    })
    const fetchAllInterviews = async () => {
        try {
            const response = await getInterviewPage(loginData.access_token);
            if (response.status == 200) {
                setInterviewData(response.data);
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching interviews:", error.response ? error.response.data : error.message);
        }
    }
    const fetchCompanyLogo = async (logo_id) => {
        try {
            const response = await axios.get(`${config.apiUrl}candidate/company-logo/${logo_id}`, {
                responseType: 'blob'
            });
            if (response.status === 200) {
                const logoUrl = URL.createObjectURL(response.data);
                setLogoUrls((prev) => ({ ...prev, [logo_id]: logoUrl })); // Store logo URL with logo_id as key
            }
        } catch (error) {
            console.error("Error fetching company logo:", error.response ? error.response.data : error.message);
        }
    };
    const fetchAllCompanyLogos = (interviews) => {
        interviews.forEach(interviewDay => {
            interviewDay.interviews.forEach(interview => {

                if (!logoUrls[interview.company_logo]) {
                    fetchCompanyLogo(interview.company_logo);

                }
            });
        });
    };
    useEffect(() => {
        if (interviewData.upcoming_interviews) {
            fetchAllCompanyLogos(interviewData.upcoming_interviews);
        }
        if (interviewData.completed_interviews) {
            fetchAllCompanyLogos(interviewData.completed_interviews);
        }
    }, [interviewData]);

    useEffect(() => {
        fetchAllInterviews()
    }, [loginData.access_token])
    return (
        <>
            <div>
                <h2 className='menu-heading pl-0 lg:mb-2'>Interview Status</h2>
                <div className="flex gap-x-4">
                    <div className="status-box">
                        <h2>Interviews for Today</h2>
                        <h3>{interviewData.stats.interviews_for_today}</h3>
                    </div>
                    <div className="status-box">
                        <h2>Pending Interviews</h2>
                        <h3>{interviewData.stats.pending_interviews}</h3>
                    </div>
                    <div className="status-box">
                        <h2>Completed Interviews</h2>
                        <h3>{interviewData.stats.completed_interviews}</h3>
                    </div>
                </div>
                <div>
                    <h2 className='menu-heading pl-0 lg:mb-2'>Your Interviews</h2>
                    <Box sx={{ width: '100%' }} className="setting-tabs">
                        <Box sx={{ borderBottom: 0, borderColor: 'divider' }} className="hirree-tabs">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                <Tab label="Upcoming " {...a11yProps(0)} />
                                <Tab label="Completed" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {interviewData.upcoming_interviews.map((interviewDay, dayIndex) => (
                                <div key={dayIndex} className="mt-4">
                                    <h3 className="mb-2 font-semibold">{interviewDay.date}</h3>
                                    <div className="grid grid-cols-3 gap-x-4 gap-y-4" >
                                        {interviewDay.interviews.map((interview, index) => (

                                            <div className="interview-card w-full h-full" key={index}>
                                                {/* {console.log(interview)} */}
                                                <div className="flex items-center gap-x-4 mt-2">
                                                    <div className="user-img">
                                                        <img src={logoUrls[interview.company_logo] || "images/default.png"} alt="Company Logo" />
                                                    </div>
                                                    <div>
                                                        <p className="text-[12px] text-[#484848] mb-1">
                                                            <img src="images/icons/clock.svg" className="inline mr-1" />
                                                            {new Date(`1970-01-01T${interview.interview_time.start_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}-
                                                            {new Date(`1970-01-01T${interview.interview_time.end_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}
                                                        </p>
                                                        <h3 className="text-[16px] text-black mb-1">{interview.job_title}</h3>
                                                        <p className="text-[12px] text-[#86888A] mb-1">
                                                            <span>{interview.company_name} -</span>{"  "}
                                                            <span>{interview.location} -</span>{"  "}
                                                            <span>{interview.salary} Lakh CTC</span>
                                                        </p>
                                                        <p className="text-[12px] text-[#484848] mb-1">
                                                            <img src={
                                                                interview.interview_type === 'Video call'
                                                                    ? "images/icons/video-call.svg"
                                                                    : interview.interview_type === 'Phone'
                                                                        ? "images/icons/phone.svg"
                                                                        : "images/icons/location.svg"
                                                            } className="inline mr-1" />
                                                            <span className="">{interview.interview_type}</span>
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {interviewData.completed_interviews.map((interviewDay, dayIndex) => (
                                <div key={dayIndex}>
                                    <h3 className="mb-2">{interviewDay.date}</h3> {/* Display the date */}
                                    <div className="grid grid-cols-3 gap-x-4 gap-y-2 mb-2">
                                        {interviewDay.interviews.map((interview, index) => (
                                            <div className="interview-card" key={index}>
                                                <div className="flex items-center gap-x-4 mt-2">
                                                    <div className="user-img">
                                                        <img
                                                            src={logoUrls[interview.company_logo] || "images/default.png"} // Fetch logo by ID
                                                            alt="Company Logo"
                                                        />

                                                    </div>
                                                    <div>
                                                        <p className="text-[12px] text-[#484848] mb-2">
                                                            <img src="images/icons/clock.svg" className="inline mr-1" />
                                                            {new Date(`1970-01-01T${interview.interview_time.start_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}-
                                                            {new Date(`1970-01-01T${interview.interview_time.end_time}`).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            }).replace(' ', '').toUpperCase()}
                                                        </p>
                                                        <h3 className="text-[16px] text-black">{interview.job_title}</h3>
                                                        <p className="text-[12px] text-[#86888A]">
                                                            <span>{interview.company_name} -</span>{"  "}
                                                            <span>{interview.location} -</span>{"  "}
                                                            <span>{interview.salary} Lakh CTC</span>
                                                        </p>
                                                        <p className="text-[12px] text-[#484848] mb-1">
                                                            <img src={
                                                                interview.interview_type === 'Video call'
                                                                    ? "images/icons/video-call.svg"
                                                                    : interview.interview_type === 'Phone'
                                                                        ? "images/icons/phone.svg"
                                                                        : "images/icons/location.svg"
                                                            } className="inline mr-1" />
                                                            <span className="">{interview.interview_type}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
        </>
    )
}
export default InterviewContent