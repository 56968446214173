import React, { useState, useEffect ,useRef} from "react";
import { getLocation } from "../../../utils/candidate.api/candidate.api.services";
import { getSkills } from "../../../utils/candidate.api/candidate.api.services";
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Select from 'react-select';
import { components } from 'react-select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getCurrency } from "../../../utils/employer.api/employer.api.services";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
//             width: 250,
//             fontSize: '10px'
//         },
//     },
// };
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200, // Set a maximum height for scrolling if necessary
            width: '250px', // Adjust the width as needed
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

const JobDetailsForm = ({ formData, setFormData, errors, setErrors }) => {
    const jobTypes = ["Full-time","Part-time" ,"Internship", "Contractual"];
    const jobModes = ["Hybrid", "Remote", "On-site"];
    const [jobTypeOpen, setJobTypeOpen] = useState(false);
    const [jobModeOpen, setJobModeOpen] = useState(false);
    const [location, setLocation] = useState([]);
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [currency, setCurrency] = useState([]);
    const dropdownRef_jobmode = useRef(null);
    const dropdownRef_jobtype = useRef(null);

    const noticePeriod = [
        { name: "Immediate/15 days" },
        { name: "30 days" },
        { name: "60 days" },
        { name: "90 days or more" },

    ];
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndtTime] = useState(null);

    const [startTimestr, setStartTimestr] = useState(''); // Store time in HH:mm format
    const [endTimestr, setendTimestr] = useState(''); // Store time in HH:mm format
    const handleTimeChange = (newTime, field) => {
        if (newTime) {
            const formattedTime = dayjs(newTime).format('HH:mm'); // Format time to HH:mm
            setFormData((prevData) => ({
                ...prevData,
                working_time: {
                    ...prevData.working_time,
                    [field]: formattedTime, // Update either start_time or end_time
                },
            }));
            console.log(`Formatted ${ field } for payload:, formattedTime`);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                working_time: {
                    ...prevData.working_time,
                    [field]: '', // Reset if no time is selected
                },
            }));
        }
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };

            // Remove specific error if field is filled
            if (newTime) {
                delete updatedErrors[field];
            }
            return {
                ...prevErrors,
                jobDetailsErrors: updatedErrors,
            };
        });
    };

    const fetchSkills = async () => {
        try {
            let response = await getSkills();
            const fetchedSkills = response.data.map(skill => skill.toLowerCase()); // Convert fetched skills to lowercase
            const skillOptions = fetchedSkills.map(skill => ({ label: skill, value: skill }));
            setSkills(skillOptions);
        
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSkills();
    }, []);
    const fetchLocation = async () => {
        try {
            let response = await getLocation();
            setLocation(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    const handleSkillChange = async (selectedOptions) => {
        const updatedSkills = selectedOptions.map((option) => ({
            name: option.value
           
        }));
       
        setFormData(prev => ({
            ...prev,
            skill_set: updatedSkills
        }));
        
    };
    useEffect(() => {
        fetchLocation();
    }, []);
    const handleCheckboxChange = (e) => {
        const { checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            is_fresher_role: checked, // Set to true if checked, false if unchecked
            work_experience_max: '',
            work_experience_min: ''
        }));
    };
    const toggleDropdown_jobtype = () => {
        setJobTypeOpen(!jobTypeOpen);
    };
    const toggleDropdown_jobmode = () => {
        setJobModeOpen(!jobModeOpen);
    };
    const handleCheckboxChange_jobtype = (event) => {
        const { value } = event.target;
        const currentJobTypes = Array.isArray(formData.job_types) ? formData.job_types : [];
        const currentIndex = currentJobTypes.indexOf(value);
        const newJobTypes = [...currentJobTypes];

        if (currentIndex === -1) {
            newJobTypes.push(value);
        } else {
            newJobTypes.splice(currentIndex, 1);
        }

        console.log("Updating job_types:", newJobTypes);
        setFormData((prevFormData) => ({
            ...prevFormData,
            job_types: Array.isArray(newJobTypes) ? newJobTypes : [],
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };
            if (newJobTypes.length > 0) {
                delete updatedErrors["job_types"];
            }
            return {
                ...prevErrors,
                jobDetailsErrors: updatedErrors,
            };
        });
    };

    const handleCheckboxChange_jobmode = (event) => {
        const { value } = event.target;
        const currentJobModes = Array.isArray(formData.job_modes) ? formData.job_modes : [];
        const currentIndex = currentJobModes.indexOf(value);
        const newJobModes = [...currentJobModes];

        if (currentIndex === -1) {
            newJobModes.push(value);
        } else {
            newJobModes.splice(currentIndex, 1);
        }

        console.log("Updating job_modes:", newJobModes);
        setFormData((prevFormData) => ({
            ...prevFormData,
            job_modes: Array.isArray(newJobModes) ? newJobModes : [],
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };
            
            if (newJobModes.length > 0 ) {
                delete updatedErrors["job_modes"];
            }
            
            
            return {
                ...prevErrors,
                jobDetailsErrors: updatedErrors,
            };
        });
    };


    // Handle input change
    const handleInputChange = (e) => {
        const { name, value, selectedOptions } = e.target;
        console.log(name, value);

       // let selectedJobTypes, selectedJobModes;
        // Handle skill_set separately
        if (name === "skill_set") {
            const selectedSkills = value;
            setSelectedSkills(selectedSkills);
            setFormData((prevData) => ({
                ...prevData,
                skill_set: selectedSkills.map(skill => ({ name: skill }))
            }));
        }
        // Handle job_types separately for multiple selections
        // else if (name === "job_types") {
        //     selectedJobTypes = Array.from(selectedOptions).map(option => option.value);
        //     setFormData((prevData) => ({
        //         ...prevData,
        //         job_types: selectedJobTypes,
        //     }));
        // }
        // else if (name === "job_modes") {
        //     console.log("handling job modes in input change");
        //     selectedJobModes = Array.from(selectedOptions).map(option => option.value);
        //     setFormData((prevData) => ({
        //         ...prevData,
        //         job_modes: selectedJobModes,
        //     }));
        // }
        // Handle other inputs
        else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: (["expected_salary_min", "expected_salary_max", "work_experience_min", "work_experience_max", "openings"].includes(name) && value !== "")
                    ? parseInt(value, 10)
                    : value || "",
            }));
        }

        // Update errors
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors.jobDetailsErrors };
            console.log("befor", updatedErrors);
            // Remove specific error if field is filled
            if (value) {
                delete updatedErrors[name];
            }
            // Clear work experience errors if fresher role is selected
            if (formData.is_fresher_role) {
                delete updatedErrors['work_experience_min'];
                delete updatedErrors['work_experience_max'];
            }
            console.log("after", updatedErrors);
            return {
                ...prevErrors,
                jobDetailsErrors: updatedErrors,
            };
        });
    };
    const handleClickOutside = (event) => {
        if (dropdownRef_jobmode.current && !dropdownRef_jobmode.current.contains(event.target)) {
            setJobModeOpen(false);
        }
        if (dropdownRef_jobtype.current && !dropdownRef_jobtype.current.contains(event.target)) {
            setJobTypeOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [jobModeOpen, jobTypeOpen]);

    useEffect(() => {
        // Whenever the form or tab is re-rendered, ensure selected skills are set from formData
        if (formData.skill_set) {
            const skillNames = formData.skill_set.map(skill => skill.name);
            setSelectedSkills(skillNames);
        }
    }, [formData]);
    const fetchCurrency = async () => {
        try {
            let response = await getCurrency()
            setCurrency(response.data)
            if (!formData.salary_currency) {
                setFormData(prev => ({ ...prev, salary_currency: "INR" }));
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCurrency()
    }, [])
    useEffect(() => {
        // Set default salary type to "Monthly" if not already set
        if (!formData.salary_type) {
            setFormData(prev => ({ ...prev, salary_type: "Monthly" }));
        }
    }, [setFormData, formData.salary_type]);
    //     console.log("job_types data type:", typeof formData.job_types, Array.isArray(formData.job_types));
    // console.log("job_modes data type:", typeof formData.job_modes, Array.isArray(formData.job_modes));

    return (
        <>
            <div className="form-theme mt-8">
                <h2 className="heading2 mb-4">Post a Job</h2>
                <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-2">
                        <div className="grid gap-y-6 gap-x-4 mb-3 md:grid-cols-2">
                            <div>
                                <label className="block mb-3">Job Title<span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="job_title"
                                    className="w-full border border-gray-300"
                                    placeholder="UI/UX Designer"
                                    value={formData.job_title || ''}
                                    onChange={handleInputChange}
                                />
                                {errors.job_title && <p className="text-xs text-red-500 mt-3">{errors.job_title}</p>}
                            </div>
                            <div className="flex flex-row gap-x-2 " ref={dropdownRef_jobtype}>
                                <div className="relative mr-2 w-full">
                                    <label htmlFor="job_types" className="block mb-3">
                                        Job Type<span className="text-red-500">*</span>
                                    </label>
                                    <div
                                        name="job_types"
                                        className="w-full border border-gray-300 p-2 h-9 rounded text-sm flex justify-between"
                                        onChange={handleInputChange}
                                        onClick={toggleDropdown_jobtype}
                                    >
                                        <span style={{ overflow: 'hidden' }} className={`ml-1 ${Array.isArray(formData.job_types) && formData.job_types.length > 0 ? '' : 'text-gray-400 text-sm'}`}>
                                            {Array.isArray(formData.job_types) && formData.job_types.length > 0
                                                ? formData.job_types.join(', ')
                                                : 'Select Job Type'}
                                        </span>

                                        <span>
                                            {jobTypeOpen ? (
                                                <KeyboardArrowUpIcon fontSize="small" />
                                            ) : (
                                                <KeyboardArrowDownIcon fontSize="small" />
                                            )}
                                        </span>
                                    </div>
                                    {jobTypeOpen && (
                                        <div className="absolute bg-white border border-gray-300 mt-1 z-10 w-full">
                                            {jobTypes.map((jobType) => (
                                                <div key={jobType} className="flex items-center p-1">
                                                    <input
                                                        type="checkbox"
                                                        value={jobType}
                                                        checked={formData.job_types && formData.job_types.includes(jobType)}
                                                        onChange={handleCheckboxChange_jobtype}
                                                        className="mr-2"
                                                    />
                                                    <span className="text-sm" >
                                                        {jobType}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {errors.job_types && <p className="text-xs text-red-500 mt-2">{errors.job_types}</p>}
                                </div>
                                <div className="relative m-0 w-full" ref={dropdownRef_jobmode}>
                                    <label htmlFor="job_modes" className="block mb-3">
                                        Job Mode<span className="text-red-500">*</span>
                                    </label>
                                    <div
                                        className="w-full border border-gray-300 p-2 h-9 rounded text-sm flex justify-between"
                                        onClick={toggleDropdown_jobmode}
                                        onChange={handleInputChange}
                                    >
                                        <span style={{overflow: 'hidden'}} className={`ml-1 ${Array.isArray(formData.job_modes) && formData.job_modes.length > 0 ? '' : 'text-gray-400 text-sm'}`}>
                                            {Array.isArray(formData.job_modes) && formData.job_modes.length > 0
                                                ? formData.job_modes.join(', ')
                                                : 'Select Job Mode'}
                                        </span>

                                        <span>
                                            {jobModeOpen ? (
                                                <KeyboardArrowUpIcon fontSize="small" />
                                            ) : (
                                                <KeyboardArrowDownIcon fontSize="small" />
                                            )}
                                        </span>
                                    </div>
                                    {jobModeOpen && (
                                        <div className="absolute bg-white border border-gray-300 mt-1 z-10 w-full">
                                            {jobModes.map((jobMode) => (
                                                <div key={jobMode} className="flex items-center p-1 ">
                                                    <input
                                                        type="checkbox"
                                                        value={jobMode}
                                                        checked={formData.job_modes && formData.job_modes.includes(jobMode)}
                                                        onChange={handleCheckboxChange_jobmode}
                                                        className="mr-2"
                                                    />
                                                    <span className="text-sm">
                                                        {jobMode}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {errors.job_modes && <p className="text-xs text-red-500">{errors.job_modes}</p>}
                                </div>
                            </div>

                            {/* notice_period */}

                            {/* department */}
                            <div>
                                <label className="block mb-3">Department<span className="text-red-500">*</span></label>
                                <select
                                    id="department"
                                    name="department"
                                    className={`w-full border border-gray-300`}
                                    value={formData.department || ''}
                                    onChange={handleInputChange}
                                    
                                >
                                    <option value="" disabled hidden>Department</option>
                                    <option value="Engineering">Engineering</option>
                                    <option value="Sales">Sales</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Finance">Finance</option>
                                    <option value="Human Resources">Human Resources</option>
                                    <option value="Operations">Operations</option>
                                    <option value="Customer Service">Customer Service</option>
                                    <option value="Research and Development">Research and Development</option>
                                    <option value="Legal">Legal</option>
                                    <option value="Information Technology">Information Technology</option>
                                    <option value="Other">Other</option>
                                </select>

                                {errors.department && <p className="text-xs text-red-500">{errors.department}</p>}
                            </div>
                            <div className="flex flex-row gap-x-2">
                                <div className="w-full mr-2">
                                    <label htmlFor="notice_period" className="block mb-3">Notice Period<span className="text-red-500">*</span></label>
                                    <select
                                        id="notice_period"
                                        name="notice_period"
                                        className="w-full border border-gray-300"
                                        value={formData.notice_period || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled hidden>Notice Period</option>
                                        {noticePeriod.map((items) => (
                                            <option key={items.name} value={items.name}>{items.name}</option>
                                        ))}
                                    </select>
                                    {errors.notice_period && <p className="text-xs text-red-500">{errors.notice_period}</p>}
                                </div>
                                {/* location */}
                                <div className="w-full m-0">
                                    <label className="block mb-3">Location<span className="text-red-500">*</span></label>
                                    <select
                                        id="location"
                                        name="location"
                                        className="w-full border border-gray-300"
                                        value={formData.location || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled hidden>Location</option>
                                        {location.map((item) => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {errors.location && <p className="text-xs text-red-500">{errors.location}</p>}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="expected_salary" className="block mb-3">Working Time<span className="text-red-500">*</span></label>
                                <div className="flex w-full gap-1">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="flex flex-col gap-1">
                                            <TimePicker
                                                className={`${formData.working_time?.end_time}?'':text-gray-500`}
                                                value={formData.working_time?.start_time ? dayjs(formData.working_time.start_time, 'HH:mm') : null}
                                                onChange={(newTime) => handleTimeChange(newTime, 'start_time')}
                                                ampm
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {errors.start_time && <p className="text-xs text-red-500">{errors.start_time}</p>}
                                        </div>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className={`flex flex-col gap-1 ${formData.working_time?.end_time ? '' : 'text-gray-400'}`}>
                                            <TimePicker
                                                value={formData.working_time?.end_time ? dayjs(formData.working_time.end_time, 'HH:mm') : null}
                                                onChange={(newTime) => handleTimeChange(newTime, 'end_time')}
                                                ampm
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            sx: {
                                                                '&::placeholder': {
                                                                    fontWeight: '500', // Adjust font weight
                                                                    color: 'gray',      // Adjust color as needed
                                                                },
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.end_time && <p className="text-xs text-red-500">{errors.end_time}</p>}
                                        </div>
                                    </LocalizationProvider>

                                </div>
                            </div>

                            {/* education */}
                            <div className="flex flex-row gap-x-2">
                                <div className="w-full mr-2">
                                    <label className="block mb-3">Education<span className="text-red-500">*</span></label>
                                    <select
                                        id="education"
                                        name="education"
                                        className="w-full border border-gray-300"
                                        value={formData.education || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled hidden>education</option>
                                        <option value="No Formal Education">No Formal Education</option>
                                        <option value="Primary Education">Primary Education</option>
                                        <option value="Secondary Education">Secondary Education</option>
                                        <option value="High School Diploma">High School Diploma</option>
                                        <option value="Vocational Training">Vocational Training</option>
                                        <option value="Associate's Degree">Associate's Degree</option>
                                        <option value="Bachelor's Degree">Bachelor's Degree</option>
                                        <option value="Post-Graduate Diploma">Post-Graduate Diploma</option>
                                        <option value="Master's Degree">Master's Degree</option>
                                        <option value="Doctorate (Ph.D.)">Doctorate (Ph.D.)</option>
                                        <option value="Post-Doctoral">Post-Doctoral</option>
                                        <option value="Professional Degree (e.g., MD, JD)">Professional Degree (e.g., MD, JD)</option>
                                        <option value="Professional Certification">Professional Certification</option>

                                    </select>
                                    {errors.education && <p className="text-xs text-red-500">{errors.education}</p>}
                                </div>
                                <div className="w-full">
                                    <label className="block mb-3">Number of openings<span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        name="openings"
                                        className=" w-full border border-gray-300"
                                        placeholder="number of openings"
                                        value={formData.openings || ''}
                                        onChange={handleInputChange}
                                        onKeyPress={(e) => {
                                            if (!/^\d*$/.test(e.key)) {  // Prevent non-numeric input
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.openings && <p className="text-xs text-red-500">{errors.openings}</p>}

                                </div>
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="expected_salary" className="block mb-3">Required Work Experience<span className="text-red-500">*</span></label>

                                <div className="flex flex-row gap-2 items-center">
                                    {/* Fresher checkbox with more space */}
                                    <div className="w-1/6">
                                        <input
                                            type="checkbox"
                                            className="text-lg"
                                            name={formData.is_fresher_role}
                                            checked={formData.is_fresher_role}
                                            onChange={handleCheckboxChange}
                                        />
                                        <div className="ml-2 font-medium text-sm inline text-center">Fresher?</div>
                                    </div>

                                    {/* Work Experience Min Section */}
                                    <div className="">
                                        <div className="relative w-full flex  items-center gap-1">
                                            <select
                                                name="work_experience_min_unit"
                                                className="country-select select-small"
                                                value={formData.work_experience_min_unit}
                                                onChange={handleInputChange}
                                                style={{ borderRadius: "5px 0 0 5px" }}
                                            >
                                                <option value="months" className="text-center text-gray-500">months</option>
                                                <option value="years" className="text-center text-gray-500">years</option>
                                            </select>

                                            <input
                                                type="text"
                                                name="work_experience_min"
                                                className={`w-32 border ${formData.is_fresher_role ? 'bg-gray-100 text-gray-500 border-gray-400 cursor-not-allowed' : 'border-gray-300'}`} // Set a fixed width
                                                placeholder="Min."
                                                value={formData.is_fresher_role ? '' : (formData.work_experience_min !== undefined ? formData.work_experience_min : '')}
                                                onChange={handleInputChange}
                                                style={{ borderRadius: "0 5px 5px 0" }}
                                                onKeyPress={(e) => {
                                                    if (!/^\d*$/.test(e.key)) {  // Prevent non-numeric input
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled={formData.is_fresher_role}
                                            />
                                        </div>
                                        {!formData.is_fresher_role && errors.work_experience_min_unit && (
                                            <p className="text-xs text-red-500">{errors.work_experience_min_unit}</p>
                                        )}
                                        {!formData.is_fresher_role && errors.work_experience_min && (
                                            <p className="text-xs text-red-500">{errors.work_experience_min}</p>
                                        )}
                                    </div>

                                    {/* Work Experience Max Section */}
                                    <div className="">
                                        <div className=" flex items-center gap-1 relative">
                                            <select
                                                name="work_experience_max_unit"
                                                className="country-select select-small"
                                                value={formData.work_experience_max_unit}
                                                onChange={handleInputChange}
                                                style={{ borderRadius: "5px 0 0 5px" }}
                                            >
                                                <option value="months" className="text-center text-gray-500">months</option>
                                                <option value="years" className="text-center text-gray-500">years</option>
                                            </select>

                                            <input
                                                type="text"
                                                name="work_experience_max"
                                                className={`w-32  border ${formData.is_fresher_role ? 'bg-gray-100 text-gray-500 border-gray-400 cursor-not-allowed' : 'border-gray-300'}`} // Set a fixed width
                                                placeholder="Max."
                                                value={formData.is_fresher_role ? '' : formData.work_experience_max || ''}
                                                onChange={handleInputChange}
                                                style={{ borderRadius: "0 5px 5px 0" }}
                                                onKeyPress={(e) => {
                                                    if (!/^\d*$/.test(e.key)) {  // Prevent non-numeric input
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled={formData.is_fresher_role}
                                            />
                                        </div>
                                        {!formData.is_fresher_role && errors.work_experience_max_unit && (
                                            <p className="text-xs text-red-500">{errors.work_experience_max_unit}</p>
                                        )}
                                        {!formData.is_fresher_role && errors.work_experience_max && (
                                            <p className="text-xs text-red-500">{errors.work_experience_max}</p>
                                        )}
                                    </div>
                                </div>

                            </div>

                            {/* </div> */}
                            {/* skills */}
                            {/* <div className="flex"> */}

                            <div className="mb-3 col-span-2">
                                <label htmlFor="expected_salary" className="block mb-3">Offered Salary<span className="text-red-500">*</span></label>
                                <div className="flex flex-row gap-1">
                                    <div className="basis-1/8">
                                        <select
                                            name="salary_currency"
                                            className="border border-gray-300 "
                                            value={formData.salary_currency || 'INR'}
                                            onChange={handleInputChange}
                                        >
                                            <option className="text-center" value="" disabled hidden>Currency</option>
                                            
                                            {currency.map((items) => (
                                                <option key={items} className="text-center" value={items}>{items}</option>
                                            ))}

                                        </select>
                                        {errors.salary_currency && <p className="text-xs text-red-500">{errors.salary_currency}</p>}
                                    </div>
                                    <div className="basis-1/6">
                                        <select
                                            name="salary_type"
                                            className="border border-gray-300 w-100"
                                            value={formData.salary_type || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled hidden>Salary Type</option>
                                            <option value="Hourly">Hourly</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="Yearly">Yearly</option>
                                            <option value="Contract">Contract</option>
                                        </select>
                                        {errors.salary_type && <p className="text-xs text-red-500">{errors.salary_type}</p>}
                                    </div>
                                    <div className="basis-1/4">
                                        <input
                                            type="text"
                                            name="expected_salary_min"
                                            className="w-full border border-gray-300"
                                            placeholder="Min."
                                            value={formData.expected_salary_min || ''}
                                            onChange={handleInputChange}
                                            onKeyPress={(e) => {
                                                if (!/^\d*$/.test(e.key)) {  // Prevent non-numeric input
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.expected_salary_min && <p className="text-xs text-red-500">{errors.expected_salary_min}</p>}
                                    </div>
                                    <div className="basis-1/4">
                                        <input
                                            type="text"
                                            name="expected_salary_max"
                                            className="w-full border border-gray-300"
                                            placeholder="Max."
                                            value={formData.expected_salary_max || ''}
                                            onChange={handleInputChange}
                                            onKeyPress={(e) => {
                                                if (!/^\d*$/.test(e.key)) {  // Prevent non-numeric input
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.expected_salary_max && <p className="text-xs text-red-500">{errors.expected_salary_max}</p>}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="mb-2 w-1/2">
                            <label htmlFor="skills" className="block mb-3">
                                Skills<span className="text-red-500">*</span> </label>
                          
                            <Select
                                isMulti
                                name="skills"
                                options={skills}
                                className="basic-multi-select w-full"
                                classNamePrefix="select"
                                onChange={handleSkillChange}
                                value={formData.skill_set.map((skill) => ({
                                    value: skill.name,
                                    label: skill.name,
                                }))}
                                placeholder="Select skills"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: '38px', // Control size
                                        display: 'flex', // Flexbox for alignment
                                        alignItems: 'center', // Vertically align the content
                                        paddingRight: '60px', // Extra space for the right-side icons (cross + down arrow)
                                        position: 'relative', // Make sure absolute icons are positioned relative to this container
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        maxHeight: '100px', // Scrollable for selected values
                                        overflowY: 'auto', // Enable scroll for overflowed values
                                        flexGrow: 1, // Let this container take remaining space
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        position: 'absolute',
                                        right: '30px', // Add space between down arrow and cross mark
                                        top: '50%',
                                        transform: 'translateY(-50%)', // Vertically center the arrow
                                    }),
                                    clearIndicator: (provided) => ({
                                        ...provided,
                                        position: 'absolute',
                                        right: '5px', // Position clear icon (cross) slightly to the left of the down arrow
                                        top: '50%',
                                        transform: 'translateY(-50%)', // Vertically center the clear icon
                                    }),
                                }}
                                components={{
                                    ValueContainer: (props) => (
                                        <div
                                            style={{
                                                maxHeight: '100px',
                                                overflowY: 'auto',
                                                flexGrow: 1, // Let this container take remaining space
                                            }}
                                            onMouseDown={(e) => e.stopPropagation()} // Prevent toggle behavior
                                        >
                                            <components.ValueContainer {...props} />
                                        </div>
                                    ),
                                }}
                            />



                            {errors.skill_set && <p className="text-xs text-red-500">{errors.skill_set}</p>}
                        </div>

                    </div>
                    {/* job description */}
                    <div className="col-span-1">
                        <label htmlFor="job_description" className="block mb-2">Job Description<span className="text-red-500">*</span></label>
                        <textarea
                            id="job_description"
                            name="job_description"
                            rows="12"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                            placeholder="Write your thoughts here..."
                            value={formData.job_description || ''}
                            onChange={handleInputChange}
                        />
                        {errors.job_description && <p className="text-xs text-red-500">{errors.job_description}</p>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobDetailsForm;
