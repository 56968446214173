import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";

const SignupFooter = () => {
    return (
        <>
            <div className="footer-content mt-4 pb-5">
                {/* <p className="py-3">Already have an account?<Link to={`${config.baseUrl}login`}> Login </Link></p> */}
                <p>By clicking ‘Create Account’, you acknowledge that you have read and accept the <Link to={`${config.baseUrl}terms-and-conditions`}>Terms of Service </Link>
                and <Link to={`${config.baseUrl}privacy-policy`}> Privacy Policy.</Link></p>
            </div>
        </>
    )
}
export default SignupFooter;