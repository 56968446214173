import React, { useState, useEffect } from "react";
import config from "../../config/config";
import * as Yup from 'yup';
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast';
import SignupFooter from "./signupFooter";
import { Auth } from "../../redux copy/actionTypes";
import { useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getCountryColde } from "../../utils/candidate.api/candidate.api.services";


const CandidateSignUp = () => {
  const [form, setForm] = useState({ email: "", name: "", password: "", confirm_password: "", phone_number: "", country_code: '+91' });
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30); // Set 2 minutes (120 seconds)
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [otpErrors, setOtpErrors] = useState("")
  const [countryCode, setCountryCode] = useState([])

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPassword = () => {
    setshowConfirmPassword((prevState) => !prevState);
  }
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character"
      )
      .required('password is required'),
    confirm_password: Yup.string()
      .required('Confirm Password is required') // Required check first
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    country_code: Yup.string().required("Please select the country code"),
    phone_number: Yup.string()
      // .matches(/^[0-9]{10}$/, 'The phone number is invalid') // Ensure exactly 10 digits
      .required('Phone number is required'),

    email: Yup.string()
      .required('Email is required')
      .email('Please enter a valid email address'),

    name: Yup.string()
      .required('Name is required')

  });
  const [errors, setErrors] = useState({});

  const fetchCountryCode = async () => {
    try {
        let response = await getCountryColde()
        setCountryCode(response.data)
    }
    catch (error) {
        console.log(error)
    }
}
useEffect(() => {
    fetchCountryCode()

}, [])
  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(form, { abortEarly: false });
      setLoading(true)
      const config1 = {
        method: 'post',
        url: `${config.apiUrl}candidate/signup`,
        data: form
      };

      const response = await axios(config1);

      if (response.status === 201) {
        console.log("Success:", response.data);
        toast.success(response.data.message);
        setShowOtpInput(true);
        setTimeLeft(30); ///timer
        setOtp("")
      } else {
        toast.error("Something went wrong");
      }

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error("Unexpected error:", error);
      }
    }
    finally {
      setLoading(false)
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      if (otp === "") {
        setOtpErrors("Please enter a valid otp")
      }
      const response = await axios.post(`${config.apiUrl}candidate/verify-otp`, { otp, email: form.email });
      if (response.status === 200) {
        toast.success("Registered successfully");
        dispatch({
          type: Auth,
          payload: response.data
        })
        window.location.href = `${config.baseUrl}candidate/dashboard`;
      } else {
        toast.error("Failed to verify OTP");
      }

    } catch (error) {
      if (otp !== "") {
        setOtpErrors(error.response.data.detail)
      }
      else {
        console.log(error)
      }
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => ({ ...old, [name]: value }));
    try {
      // await validationSchema.validateAt(name, { [name]: value });
      setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
    }
  };
  const resendOtp = async () => {

    try {
      setLoading(true)
      const response = await axios.post(`${config.apiUrl}candidate/resend-otp`, { email: form.email });
      if (response.status === 200) {
        toast.success(response.data.message);
        setCanResendOtp(false); // Disable resend button
        setTimeLeft(30); // Reset timer
      } else {
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      toast.error("Error while resending OTP");
    }
    finally{
      setLoading(false)
    }
  };
  useEffect(() => {
    let timer;
    if (showOtpInput && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setCanResendOtp(true);
    }

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [showOtpInput, timeLeft]);
  return (
    <>
      <Toaster />
      <div className="">
        {!showOtpInput ? (
          <form className="space-y-3" onSubmit={handleSignup}>
            <div className="mt-0">
              <label className="block">Name<span className="text-red-500">*</span></label>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your name"
              />
              {errors.name && <span className="text-red-500 text-xs">{errors.name}</span>}
            </div>
            <div className="mt-0">
              <label className="block">Email address<span className="text-red-500">*</span></label>
              <input
                type="text"
                id="email"
                name="email"
                value={form.email}
                onChange={inputHandler}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your email"
              />
              {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
            </div>
            <div className="mt-0">
              <label className="block">Phone number<span className="text-red-500">*</span></label>
         <div className="relative flex">
         <select
                name="country_code"
                className="country-select"
                value={form.country_code}
                onChange={inputHandler}
                style={{ borderRadius: "5px 0 0 5px" }}
              >
                <option value="+91" className="text-center text-gray-100"><span className="text-gray-100">+91</span></option>
                {countryCode.map((items) => (
                  <option key={items} className="text-center" value={items}>{items}</option>
                ))}
              </select>
              <input
                type="text"
                name="phone_number"
                value={form.phone_number}
                onChange={inputHandler}
                className={`mt-1  phone-box  w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.phone_number ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter your phone number"
              />
         </div>
              {errors.phone_number && <span className="text-red-500 text-xs">{errors.phone_number}</span>}
            </div>
            <div className="mt-0">
              <label className="block">Password<span className="text-red-500">*</span></label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={inputHandler}
                  className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Enter your password"
                />
                {errors.password && <span className="text-red-500 text-xs">{errors.password}</span>}
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1 cursor-pointer"
                >
                  {showPassword ? <VisibilityOffIcon fontSize="14px" /> : <VisibilityIcon fontSize="14px" />}
                </span>
              </div>
            </div>
            <div className="mt-0">
              <label className="block">Confirm Password<span className="text-red-500">*</span></label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={form.confirm_password}
                  onChange={inputHandler}
                  className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Confirm password"
                />
                {errors.confirm_password && <span className="text-red-500 text-xs">{errors.confirm_password}</span>}
                <span
                  onClick={toggleConfirmPassword}
                  className="absolute right-3 top-1 cursor-pointer"
                >
                  {showConfirmPassword ? <VisibilityOffIcon fontSize="14px" /> : <VisibilityIcon fontSize="14px" />}
                </span>
              </div>
            </div>
            {/* <div className="flex items-center mb-4">
              <input id="default-checkbox" type="checkbox" />
              <label htmlFor="default-checkbox" className="ms-2 dark:text-gray-300">Remember me<span className="text-red-500">*</span></label>
            </div> */}
            <div className="mt-0">
              <button type="submit" className="theme-btn rounded-md mr-2 text-white">Sign up
                {loading == true ?
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }} />
                  : null}

              </button>
            </div>
          </form>
        )
          :
          (<form className="space-y-4 mt-4" onSubmit={handleOtpSubmit}>
            <div className="mt-0">
              <label className="block">Enter OTP<span className="text-red-500">*</span></label>
              <input
                type="text"
                name="otp"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value); // Update the email value
                  setOtpErrors(undefined);
                }}
                className={`mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm 
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter the OTP"
              />
              {otpErrors && <span className="text-red-500 text-xs">{otpErrors}</span>}
            </div>
            <div className="mt-0">
              <button type="submit" className="theme-btn rounded-md mr-2 text-white">Submit OTP 
              {loading && (
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }}
                  />
                )}
              </button>
            </div>
            {timeLeft > 0 ? (
              <p className="text-sm">Resend OTP in {Math.floor(timeLeft / 30)}:{String(timeLeft % 30).padStart(2, '0')}</p>
            ) : (
              <p className="text-sm">
                Didn't get the OTP?{" "}
                <span className="text-blue-500 text-sm cursor-pointer" onClick={resendOtp}>
                  Resend OTP
                </span>
              </p>
            )}
          </form>)

        }
      </div>
    </>
  );
};

export default CandidateSignUp;
