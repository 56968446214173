import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';


const JobPreviewForm = ({ formData }) => {
    const [previewData, setPreviewData] = useState({});

    useEffect(() => {
        // Update previewData whenever formData changes
        setPreviewData(formData);
        // Optional: Log data to ensure it's updating
        // console.log('Updated formData:', formData);
    }, [formData]);
    return (
        <div className="w-full relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                {/* <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Field</th>
                        <th scope="col" className="px-6 py-3">Value</th>
                    </tr>
                </thead> */}
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Job Title
                        </th>
                        <td className="px-6 py-2">{formData.job_title}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Job Type
                        </th>
                        <td className="px-6 py-2">{formData.job_types.join(", ")}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Job Mode
                        </th>
                        <td className="px-6 py-2">{formData.job_modes.join(", ")}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Location
                        </th>
                        <td className="px-6 py-2">{formData.location}</td>
                    </tr>
                    {/* <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Work Experience Min
                        </th>
                        <td className="px-6 py-2">{formData.work_experience_min}</td>
                    </tr> */}
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Work Experience
                        </th>
                        <td className="px-6 py-2">
                            {!previewData.is_fresher_role ? `${previewData.work_experience_min} ${previewData.work_experience_min_unit}-${previewData.work_experience_max} ${previewData.work_experience_max_unit} ` : 'Fresher'}
                            
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Notice Period
                        </th>
                        <td className="px-6 py-2">{formData.notice_period}</td>
                    </tr>
                    {/* <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Expected Salary (Min)
                        </th>
                        <td className="px-6 py-2">{formData.expected_salary_min}</td>
                    </tr> */}
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Offered Salary
                        </th>
                        <td className="px-6 py-2">{formData.expected_salary_min} - {formData.expected_salary_max} {formData.salary_currency} {formData.salary_type}</td>
                    </tr>
                    
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Skills
                        </th>
                        <td className="px-6 py-2">
                            {formData.skill_set.map(skill => skill.name).join(', ')}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Department
                        </th>
                        <td className="px-6 py-2">{formData.department}</td>
                    </tr>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Number of openings
                        </th>
                        <td className="px-6 py-2">{formData.openings}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Education
                        </th>
                        <td className="px-6 py-2">{formData.education}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            working Time
                        </th>
                        <td className="px-6 py-2">
                            {dayjs(formData.working_time.start_time, 'HH:mm').format('hh:mm A')} - {dayjs(formData.working_time.end_time, 'HH:mm').format('hh:mm A')}
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Job Description
                        </th>
                        <td className="px-6 py-2">{formData.job_description}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default JobPreviewForm;
